import { createTheme, CSSProperties } from 'MaterialUIComponents';
import * as scssStyles from '$CSS/settings.scss';

export const muiStyles = () => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
    } as CSSProperties,
    content: {
        flexGrow: 1,
        backgroundColor: '#FAFDFF',
        padding: '0',
        minHeight: 0,
        minWidth: 0, // So the Typography noWrap works
    },
});

export const muiTheme = createTheme({
    palette: {
        background: {
            default: '#FAFDFF',
        },
        primary: {
            main: '#000',
        },
    },
    typography: {
        fontFamily: `${scssStyles.fontFamily}`,
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    fontSize: '2rem',
                    fontWeight: 'bold',
                },
                outlined: {
                    padding: '0 1.5rem',
                },
                contained: {
                    padding: '0 1.5rem',
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '1rem',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '1rem',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: '2.5rem',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2.5rem',
                },
            },
        },
    },
});
