import { bind, React, _, NavLink } from "Imports";

import { Button, Card, CardActions, CardHeader, CardContent, TextValidator, CardMedia, Typography } from "MaterialUIComponents"

import { IdentityService, IIdentityServiceInjectedProps } from "$State/IdentityFreezerService";
import { ConfigService, IConfigServiceInjectedProps } from "$State/ConfigFreezerService";
import { GetImageUrl } from "$Utilities/dataModelUtilities";
import { ValidatorForm } from "react-material-ui-form-validator";

type IForgotPasswordProps =  IIdentityServiceInjectedProps & IConfigServiceInjectedProps;


interface IForgotPasswordState {
    username: string;
    errorMsg: string;
    successMsg: string;
}

const styles = require("./ForgotPassword.scss") as {
    main: string;
    content: string;
    errorMessages: string;
    actions: string;
    actionText: string;
    logo: string;
    resetButton: string;
}

class _ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
    state: IForgotPasswordState = {
        username: '',
        errorMsg: '', 
        successMsg: ''
    };

    @bind
    sendPasswordReset() {
        this.props.identity.forgotPassword(this.state.username)
            .then((r) => {
                const { forgotPassworResult } = this.props.identity.getState();
                console.log(forgotPassworResult)
                this.setState({username: '', errorMsg: '', successMsg: "Reset password email sent"})
            })
            .catch((e) => {
                console.log(e)
                if (e.status === 401)
                    this.setState({errorMsg: "Invalid username"})
                else
                    this.setState({errorMsg: "An error ocurred in the server"})
            })
    }

    handleUserFieldChange(event: React.ChangeEvent<HTMLInputElement>) : void {
        const username = event.target.value;
        this.setState({ username: username, errorMsg: '', successMsg: '' });
    }

    render(): JSX.Element {
        const { username, errorMsg, successMsg } = this.state;
        let currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        let currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        return (
            <div className={styles.main}>
                <Card className={styles.content}>
                    <CardHeader
                        title="Forgot Password"
                        titleTypographyProps={{'variant': 'h2'}}
                    />

                    <ValidatorForm
                        ref="form"
                        onSubmit={this.sendPasswordReset}
                    >
                        <CardContent>
                            { errorMsg && (
                                <Typography className={styles.errorMessages}>
                                    { errorMsg }
                                </Typography>
                            )}
                            { successMsg && (
                                <Typography>
                                    { successMsg }
                                </Typography>
                            )}
                            <TextValidator
                                name="username"
                                label="Username"
                                type="text"
                                margin="normal"
                                fullWidth={true}
                                variant="outlined"
                                value={username}
                                validators={['required']}
                                errorMessages={['Username is required']}
                                required={true}
                                onChange={(e: any) => this.handleUserFieldChange(e)}
                            />
                        </CardContent>

                        <CardActions className={styles.actions}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                className={styles.resetButton}
                            >
                                Send Reset Password
                            </Button>
                            <br/>
                            <Typography>
                                An email will be sent to you with a link to reset your password.
                            </Typography>
                            <NavLink className={styles.actionText} exact={true} to="/login">
                                    Back to Login
                            </NavLink>
                        </CardActions>
                    </ValidatorForm>

                    <CardMedia
                        alt="VideoProtects logo"
                        className={styles.logo}
                        component="img"
                        image={GetImageUrl(currentImagesBucket? currentImagesBucket : '', currentRegion? currentRegion : '', 'logo.png')}
                    />

                </Card>
            </div>
        );
    }
}

export const ForgotPassword = IdentityService.inject(ConfigService.inject(_ForgotPassword));