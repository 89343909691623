import { bind, React, withRouter, RouteComponentProps } from '../../Imports';

import { Button } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';

import { Page } from "$Components/Shared/Page";
import { PageHeader } from "$Components/Shared/PageHeader";
import { PageContent } from "$Components/Shared/PageContent";
import { PageFooter } from "$Components/Shared/PageFooter";

import { CameraSelect } from './CameraSelect';
import { VehicleSelect } from './VehicleSelect';
import { ConfirmPair } from './ConfirmPair';

import { CameraDeviceResponse, CreatePairingRequest, VehicleResponse } from '$Generated/api';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService'; 
import { IDevicePairServiceInjectedProps, DevicePairService } from "$State/DevicePairFreezerService";

interface ICreateDevicePairBaseProps {}

type ICreateDevicePairProps = ICreateDevicePairBaseProps
    & RouteComponentProps
    & IConfigServiceInjectedProps
    & IDevicePairServiceInjectedProps;

interface ICreateDevicePairState {
    currentStep: number;
    selectedCamera?: CameraDeviceResponse;
    selectedVehicle?: VehicleResponse;
    showBarcodeScanDialog: boolean;
}

const styles = require('./CreateDevicePair.scss') as {
    buttonSolid: string;
    buttonLabel: string;
    buttonOutlined: string;
    buttonIcon: string;
    nextIcon: string;
};

class _CreateDevicePair extends React.Component<ICreateDevicePairProps, ICreateDevicePairState> {
    state: ICreateDevicePairState = {
        currentStep: 1,
        selectedCamera: undefined,
        selectedVehicle: undefined,
        showBarcodeScanDialog: false
    };

    @bind
    onSelectCamera(camera?: CameraDeviceResponse): void {
        this.setState({ selectedCamera: camera });
    }

    @bind
    onSelectVehicle(vehicle?: VehicleResponse): void {
        this.setState({ selectedVehicle: vehicle });
    }

    @bind
    backToPairList(): void {
        this.props.history.push('/pairlist');
    }

    @bind
    async onCreatePair(): Promise<void> {
        const { selectedCamera, selectedVehicle } = this.state;

        try {
            // create the new pair
            const request: CreatePairingRequest = {
                // installer's fleetId set on backend
                cameraDeviceId: selectedCamera?.id,
                integrationPartnerVehicleId: selectedVehicle?.integrationPartnerVehicleId,
                vehicleName: selectedVehicle?.name,
                telematicProviderId: selectedVehicle?.telematicDevice?.providerId,
                telematicSerialNumber: selectedVehicle?.telematicDevice?.serialNumber,
                telematicType: selectedVehicle?.telematicDevice?.type
            };

            await this.props.devicePair.createDevicePair(request);

            // refresh paired and unpaired devices lists
            await this.props.devicePair.getPairedDevices();
            await this.props.devicePair.getUnpairedCameras();
            await this.props.devicePair.getUnpairedVehicles();

            this.backToPairList();
        }
        catch (ex) {
            // TODO: show error message back to installer
        }
    }

    @bind
    onSetStep(nextStep: number): void {
        this.setState({ currentStep: nextStep });
    }

    render(): JSX.Element {
        const { currentStep, selectedCamera, selectedVehicle } = this.state;
        const isGeotab = this.props.config.getIsIntegrationPlatformGeotab();
        const pageTitle = currentStep == 1 ? 'Select Camera Device'
            : currentStep == 2 ? `Select ${isGeotab ? 'GO Device' : 'Vehicle'}`
            : 'Confirm Devices'; // currentStep == 3

        return (
            <Page>
                <PageHeader pageTitle={pageTitle} />

                <PageContent>
                    <div hidden={currentStep !== 1}>
                        <CameraSelect onSelectCamera={this.onSelectCamera} />
                    </div>
                    <div hidden={currentStep !== 2}>
                        <VehicleSelect onSelectVehicle={this.onSelectVehicle} />
                    </div>
                    <div hidden={currentStep !== 3}>
                        <ConfirmPair
                            camera={selectedCamera!}
                            vehicle={selectedVehicle!}
                        />
                    </div>
                </PageContent>

                <PageFooter
                    right={
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                classes={{outlined: styles.buttonOutlined, root: styles.buttonLabel}}
                                onClick={() => currentStep == 1 ? this.backToPairList() : this.onSetStep(currentStep - 1)}
                                startIcon={<FontAwesomeIcon icon={faAngleLeft} className={styles.buttonIcon} />}
                                style={{ marginRight: '1rem' }}
                            >
                                Back
                            </Button>

                            { currentStep < 3 ? (
                                <Button
                                    variant="contained"
                                    classes={{root: styles.buttonSolid}}
                                    onClick={() => this.onSetStep(currentStep + 1)}
                                    disabled={
                                        currentStep == 1 ? !selectedCamera
                                        : currentStep == 2 ? !selectedVehicle
                                        : false // currentStep == 3
                                    }
                                    endIcon={<FontAwesomeIcon icon={faAngleRight} className={styles.nextIcon} />}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    classes={{root: styles.buttonSolid}}
                                    onClick={this.onCreatePair}
                                >
                                    Confirm
                                </Button>
                            )}
                        </React.Fragment>
                    }
                />
            </Page>
        );
    }
}

export const CreateDevicePair = withRouter(
    DevicePairService.inject(
    ConfigService.inject(
        _CreateDevicePair
    ))
);
