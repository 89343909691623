import { bind, React } from 'Imports';

import { Button, TextField, Typography } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { VPBarcodeScanner } from './VPBarcodeScanner';

interface IDeviceSearchProps {
    label: string;
    foundCount: number;
    totalCount: number;
    showScanButton: boolean;
    onChange: (search: string) => void;
}

interface IDeviceSearchState {
    search: string;
}

const styles = require('./VPDeviceSearch.scss') as {
    container: string;
    input: string;
    clear: string;
    scan: string;
    count: string;
    faBlackIcon: string;
};

class _DeviceSearch extends React.PureComponent<IDeviceSearchProps, IDeviceSearchState> {
    state: IDeviceSearchState = { search: '' };

    @bind
    handleSearchChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const search = e.target.value ? e.target.value.toUpperCase() : '';

        this.setSearch(search);
    }

    @bind
    handleBarcodeScan(result: string): void {
        this.setSearch(result.toUpperCase());
    }

    render(): JSX.Element {
        const { label, foundCount, totalCount } = this.props;
        const { search } = this.state;

        return (
            <div className={styles.container}>
                <TextField classes={{ root: styles.input }} label={label} value={search} onChange={this.handleSearchChange} />

                <Button
                    className={styles.clear}
                    variant="text"
                    onClick={() => {
                        search && this.setSearch('');
                    }}
                    hidden={!search}
                >
                    {!search ? (
                        <FontAwesomeIcon icon={faSearch} className={styles.faBlackIcon} />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} className={styles.faBlackIcon} />
                    )}
                </Button>

                {this.props.showScanButton && (
                    <div className={styles.scan}>
                        <VPBarcodeScanner onScan={this.handleBarcodeScan} />
                    </div>
                )}

                <Typography className={styles.count} hidden={!search}>
                    {foundCount} of {totalCount}
                </Typography>
            </div>
        );
    }

    private setSearch(search: string): void {
        this.setState({ search: search });

        if (this.props.onChange) {
            this.props.onChange(search);
        }
    }
}

export const VPDeviceSearch = _DeviceSearch;
