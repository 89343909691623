import { React } from '../../Imports';

import { Grid, Typography } from 'MaterialUIComponents';

import { VPCameraCard } from '$Components/Shared/VPCameraCard';
import { VPVehicleCard } from '$Components/Shared/VPVehicleCard';
import { CameraDeviceResponse, VehicleResponse } from '$Generated/api';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

interface IConfirmPairBaseProps {
    camera?: CameraDeviceResponse;
    vehicle?: VehicleResponse;
}

type IConfirmPairProps = IConfirmPairBaseProps
    & IConfigServiceInjectedProps;

interface IConfirmPairState {}

const styles = require('./ConfirmPair.scss') as {
    device: string;
    header: string;
};

class _ConfirmPair extends React.Component<IConfirmPairProps, IConfirmPairState> {
    render(): JSX.Element {
        const { camera, vehicle } = this.props;
        const isGeotab = this.props.config.getIsIntegrationPlatformGeotab();
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        className={styles.header}
                        component="h3"
                        variant="h5"
                    >
                        {`Please review your selected camera and ${isGeotab ? 'GO Device' : "vehicle" } below.`}
                    </Typography>
                </Grid>

                <Grid item xs={12} lg={6} className={styles.device}>
                    { camera && (
                        <VPCameraCard camera={camera} />
                    )}
                </Grid>

                <Grid item xs={12} lg={6} className={styles.device}>
                    { vehicle && (
                        <VPVehicleCard vehicle={vehicle} />
                    )}
                </Grid>
            </Grid>
        )
    }
}

export const ConfirmPair = ConfigService.inject(_ConfirmPair);
