import { bind, React } from '../../Imports';

import { Grid, Typography } from 'MaterialUIComponents';

import { stringItemFilter } from '$Components/Shared/DataUtility';
import { VPDeviceSearch } from '$Components/Shared/VPDeviceSearch';
import { VPVehicleCard } from '$Components/Shared/VPVehicleCard';

import { VehicleResponse, TelematicDeviceResponse } from '$Generated/api';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IDevicePairServiceInjectedProps, DevicePairService } from "$State/DevicePairFreezerService";

interface IVehicleSelectBaseProps {
    onSelectVehicle: (vehicle?: VehicleResponse) => void;
}

type IVehicleSelectProps = IVehicleSelectBaseProps
    & IConfigServiceInjectedProps
    & IDevicePairServiceInjectedProps;

interface IVehicleSelectState {
    vehicles: VehicleResponse[];
    vehicleCount: number;
    selectedVehicleId?: string;
}

const styles = require('./VehicleSelect.scss') as {
    search: string;
    container: string;
    vehicle: string;
    selected: string;
    noVehicles: string;
};

class _VehicleSelect extends React.Component<IVehicleSelectProps, IVehicleSelectState> {
    state: IVehicleSelectState = {
        vehicles: [],
        vehicleCount: 0,
        selectedVehicleId: undefined,
    };

    componentDidMount(): void {
        this.setFilteredVehicles();
    }

    @bind
    selectVehicle(vehicle?: VehicleResponse): void {
        this.setState((state) => {
            // toggle selection
            const selectedVehicleId = state.selectedVehicleId !== vehicle?.integrationPartnerVehicleId ? vehicle?.integrationPartnerVehicleId: undefined;
            this.props.onSelectVehicle(selectedVehicleId ? vehicle : undefined);

            return {
                selectedVehicleId: selectedVehicleId
            };
        });
    }

    render(): JSX.Element {
        const { vehicles, vehicleCount, selectedVehicleId } = this.state;
        const isGeotab = this.props.config.getIsIntegrationPlatformGeotab();
        return (
            <React.Fragment>
                <div className={styles.search}>
                    <VPDeviceSearch
                        label="Search"
                        foundCount={vehicles.length}
                        totalCount={vehicleCount}
                        onChange={this.setFilteredVehicles}
                        showScanButton={isGeotab}
                    />
                </div>

                <Grid container className={styles.container}>
                    { !vehicles.length ? (
                        <Typography component="h2" variant="h5" className={styles.noVehicles}>
                            {`${isGeotab ? 'GO Device' : 'Vehicle'} not found. Please contact your Fleet Manager.`}
                        </Typography>
                    ) : vehicles.map((x) => {
                        const isSelected: boolean = !!selectedVehicleId && (x.integrationPartnerVehicleId === selectedVehicleId);

                        return (
                            <Grid item xs={12} lg={6} key={x.integrationPartnerVehicleId} className={styles.vehicle}>
                                <VPVehicleCard
                                    className={isSelected ? styles.selected : ''}
                                    onClick={() => this.selectVehicle(x)}
                                    vehicle={x}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    }

    @bind
    private setFilteredVehicles(search?: string): void {
        const freezer = this.props.devicePair.getState();
        const allVehicles = freezer.vehicleResults.data || [];
        let vehicles: VehicleResponse[];

        if (this.props.config.getIsIntegrationPlatformGeotab()) {
            type VehicleViewModelHelper = VehicleResponse & TelematicDeviceResponse;
            const allVehiclesHelper = allVehicles.map((v) => {
                return {
                    name: v.name,
                    integrationPartnerVehicleId: v.integrationPartnerVehicleId,
                    serialNumber: v.telematicDevice?.serialNumber,
                    type: v.telematicDevice?.type
                } as VehicleViewModelHelper
            });

            const vehiclesHelper = stringItemFilter(allVehiclesHelper, ['serialNumber', 'name', 'type', 'integrationPartnerVehicleId'], search) as VehicleViewModelHelper[];
            vehicles = allVehicles.filter((v) => vehiclesHelper.findIndex((vh) => vh.integrationPartnerVehicleId === v.integrationPartnerVehicleId) > -1);
        }
        else {
            vehicles = stringItemFilter(allVehicles, ['name'], search) as VehicleResponse[];
        }

        // deselect currently selected vehicle if it was filtered out
        if(!vehicles.find(x => x.integrationPartnerVehicleId === this.state.selectedVehicleId)) {
            this.selectVehicle(undefined);
        }

        // auto-select vehicle if only one found _and_ matches the search exactly (i.e., through a barcode scan)
        if((vehicles.length === 1) && (vehicles[0].telematicDevice?.serialNumber === search) && (this.state.selectedVehicleId !== vehicles[0].id)) {
            this.selectVehicle(vehicles[0]);
        }

        this.setState({
            vehicles: vehicles,
            vehicleCount: allVehicles.length
        });
    }
}

export const VehicleSelect = 
    DevicePairService.inject(
    ConfigService.inject(
        _VehicleSelect
    ));
