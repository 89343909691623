import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { WithStyles, withStyles, StylesProvider, makeStyles } from '@mui/styles';
import { CSSProperties } from '@mui/styles/withStyles';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Grid, { GridProps } from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Autocomplete from '@mui/lab/Autocomplete';

import { TextValidator, ValidatorComponentProps, ValidatorForm } from 'react-material-ui-form-validator';

type KnownValidatorComponent = React.Component<ValidatorComponentProps> & { invalid: number[] };

const InputMask = require('react-input-mask');

export {
    createTheme,
    ThemeProvider,
    StylesProvider,
    CSSProperties,
    StyledEngineProvider,
    makeStyles,
    withStyles,
    WithStyles,
    Button,
    IconButton,
    Checkbox,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    CircularProgress,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    GridProps,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Autocomplete,
    TextValidator,
    ValidatorForm,
    KnownValidatorComponent,
    InputMask,
};
