import { React, _, bind } from '../../Imports';
import { Button, Dialog, DialogContent, DialogActions, Typography } from 'MaterialUIComponents';

const styles = require("./UnpairConfirmDialog.scss") as {
    dialogContent: string;
    dialogActions: string;
    buttonSolid: string;
    buttonLabel: string;
    buttonOutlined: string;
    buttonIcon: string;
}

interface IUnpairConfirmDialogProps {
    onUnpair: () => void;
    onCancel: () => void;
}

class _UnpairConfirmDialog extends React.Component<IUnpairConfirmDialogProps, {}> {

    @bind
    cancel(): void {
        this.props.onCancel();
    }

    @bind
    unpair(): void {
        this.props.onUnpair();
    }

    render(): JSX.Element {
        return (
            <Dialog open={true}>
                <DialogContent className={styles.dialogContent}>
                    <Typography>
                        <strong>Are you sure you want to unpair this device?</strong>
                    </Typography>
                </DialogContent>
                <DialogActions className={styles.dialogActions}>
                    <Button
                        onClick={this.unpair}
                        classes={{root: styles.buttonSolid}}
                        variant="contained"
                    >
                        Yes, unpair device
                    </Button>

                    <Button
                        onClick={this.cancel}
                        classes={{outlined: styles.buttonOutlined, root: styles.buttonLabel}}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export const UnpairConfirmDialog = _UnpairConfirmDialog;
