import { FreezerService, IAjaxState, managedAjaxUtil } from "../Imports";
import { IdentityApiFactory, InstallerUserResponse, LoginUserRequest } from '$Generated/api';

const InjectedPropName = 'identity';

interface IIdentityState {
    currentUserResult: IAjaxState<InstallerUserResponse>;
    loginResult: IAjaxState<InstallerUserResponse>;
    logoutResult: IAjaxState<void>;
    setFleetResult: IAjaxState<void>;
    forgotPassworResult: IAjaxState<void>;
    resetPasswordResult: IAjaxState<void>;
    verifyResult: IAjaxState<void>;
    verifyTokenResult: IAjaxState<void>;
    fleetName: string;
    hasError: boolean;
}

class IdentityFreezerService extends FreezerService<IIdentityState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                currentUserResult: managedAjaxUtil.createInitialState(),
                loginResult: managedAjaxUtil.createInitialState(),
                logoutResult: managedAjaxUtil.createInitialState(),
                setFleetResult: managedAjaxUtil.createInitialState(),
                forgotPassworResult: managedAjaxUtil.createInitialState(),
                resetPasswordResult: managedAjaxUtil.createInitialState(),
                verifyResult: managedAjaxUtil.createInitialState(),
                verifyTokenResult: managedAjaxUtil.createInitialState(),
                fleetName: '',
                hasError: false
            },
            InjectedPropName
        );
    }

    public async login(model: LoginUserRequest): Promise<void | InstallerUserResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "loginResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if(params === undefined) {
                    throw new Error("Parameters is undefined");
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityLoginPost(params);
            },
            params: {
                body: model
            },
            onOk: (data: InstallerUserResponse) => {
                this.freezer.get().set({
                    hasError: false
                });

                return data;
            },
            onError: () => {
                this.resetUserOnFailure();
            }
        })
    }

    public async logout(): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "logoutResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityLogoutPost(params);
            },
            params: {},
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async setFleet(fleetId: number): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "setFleetResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if(params === undefined) {
                    throw new Error("Parameters is undefined");
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentitySetFleetPost(params);
            },
            params: {
                body: fleetId
            },
            onOk: () => {
                const freezerState = this.freezer.get();
                const currentFleet = freezerState.loginResult.data?.fleets?.find((x) => x.id === fleetId);

                // set fleet name on initial login
                freezerState.set({
                    fleetName: currentFleet?.name,
                    hasError: false
                });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async forgotPassword(username: string): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "forgotPasswordResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if(params === undefined) {
                    throw new Error("Parameters is undefined");
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityForgotPasswordPost(params);
            },
            params: {
                body: username
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async resetPassword(user: LoginUserRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "resetPasswordResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if(params === undefined) {
                    throw new Error("Parameters is undefined");
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityResetPasswordPost(params);
            },
            params: {
                body: user
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async verifyEmail(user: LoginUserRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "verifyResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if(params === undefined) {
                    throw new Error("Parameters is undefined");
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityVerifyPost(params);
            },
            params: {
                body: user
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async verifyToken(user: LoginUserRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "verifyTokenResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if(params === undefined) {
                    throw new Error("Parameters is undefined");
                }

                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityVerifyTokenPost(params);
            },
            params: {
                body: user
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async getCurrentUser(): Promise<void | InstallerUserResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: "currentUserResult",
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                const identityApi = IdentityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return identityApi.apiV1IdentityCurrentuserGet(params);
            },
            params: {},
            onOk: (data: InstallerUserResponse) => {
                const freezerState = this.freezer.get();

                if (data !== undefined) {
                    // set fleet name on logged-in user return
                    if(data.currentFleetId) {
                        const currentFleet = data.fleets?.find((x) => x.id === data.currentFleetId);

                        freezerState.set({
                            fleetName: currentFleet?.name,
                            hasError: false
                        });
                    }

                    freezerState.set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.resetUserOnFailure();
            }
        })
    }

    private resetUserOnFailure(): void {
        this.freezer.get().set({
            // ensure failure resets user to nothing
            currentUserResult: managedAjaxUtil.createInitialState(),
            loginResult: managedAjaxUtil.createInitialState(),
            setFleetResult: managedAjaxUtil.createInitialState(),
            fleetName: '',
            hasError: true
        });
    }
}

export const IdentityService = new IdentityFreezerService();
export type IIdentityServiceInjectedProps = ReturnType<
    IdentityFreezerService['getPropsForInjection']
>;
