import { React, BarcodeService, bind } from 'Imports';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';

interface IBarcodeScannerProps {
    onScan: (code: string) => void;
}

interface IBarcodeScannerState {
    showDialog: boolean;
}

const styles = require('./VPBarcodeScanner.scss') as {
    scannerViewport: string;
    buttonLabel: string;
    buttonIcon: string;
    buttonOutlined: string;
};

class _BarcodeScanner extends React.PureComponent<IBarcodeScannerProps, IBarcodeScannerState> {
    state = {
        showDialog: false,
    };

    private _barcodeService = new BarcodeService();
    private _viewportClass: string = styles.scannerViewport;

    @bind
    showDialog(): void {
        // wait for next render cycle after component is mounted to locate viewport element
        setTimeout(async () => {
            await this.initializeScanner();
        }, 0);

        this.setState({ showDialog: true });
    }

    @bind
    closeDialog(): void {
        this._barcodeService.stop();

        this.setState({ showDialog: false });
    }

    @bind
    async initializeScanner(): Promise<void> {
        this._barcodeService.stop();

        await this._barcodeService.start(`.${this._viewportClass}`, (result) => {
            this.props.onScan(result);
            this.closeDialog();
        });
    }

    render(): JSX.Element {
        const { showDialog } = this.state;

        return (
            <React.Fragment>
                <Button
                    variant="outlined"
                    classes={{ outlined: styles.buttonOutlined, root: styles.buttonLabel }}
                    onClick={this.showDialog}
                    startIcon={<FontAwesomeIcon icon={faCamera} className={styles.buttonIcon} />}
                >
                    Scan
                </Button>

                <Dialog maxWidth="lg" keepMounted={false} open={showDialog}>
                    <DialogTitle sx={{ fontSize: '2.5rem', fontWeight: 700 }}>Scan Device Barcode</DialogTitle>
                    <DialogContent>
                        <div className={this._viewportClass}></div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={this.initializeScanner}
                            classes={{ outlined: styles.buttonOutlined, root: styles.buttonLabel }}
                            variant="outlined"
                        >
                            Restart Scanner
                        </Button>

                        <Button onClick={this.closeDialog} variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export const VPBarcodeScanner = _BarcodeScanner;
