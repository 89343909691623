import { React } from '../../Imports';

const styles = require("./PageFooter.scss") as {
    main: string;
    left: string;
    center: string;
    right: string;
};

interface IPageFooterProps {
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
}

export class PageFooter extends React.PureComponent<IPageFooterProps, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.main}>
                { this.props.left ? (
                    <div className={styles.left}>
                        {this.props.left}
                    </div>
                ) : null }

                { this.props.center ? (
                    <div className={styles.center}>
                        {this.props.center}
                    </div>
                ): null }

                { this.props.right ? (
                    <div className={styles.right}>
                        {this.props.right}
                    </div>
                ) : null }
            </div>
        );
    }
}