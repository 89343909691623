import Quagga, { QuaggaJSConfigObject, QuaggaJSResultCallbackFunction, QuaggaJSResultObject } from '@ericblade/quagga2';

class _BarcodeService {
    private _scanner = Quagga;
    private _detectCallback?: QuaggaJSResultCallbackFunction;

    private _config: QuaggaJSConfigObject = {
        inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: '',
            area: {
                bottom: '0%',
                left: '0%',
                right: '0%',
                top: '0%'
            },
            constraints: {
                frameRate: { min: 5, max: 15 },
                width: 480,
                height: 1280,
                facingMode: 'environment'
            },
        },
        decoder: {
            readers: [
                'code_128_reader',
                'code_39_reader'
            ]
        },
        locate: false
    };

    public async start(target: string, callback: (result: string) => void): Promise<void> {
        this._config.inputStream!.target = target;

        try {
            await this._scanner.init(
                this._config,
                (err) => {
                    if(err) {
                        return;
                    }

                    this._detectCallback = (result: QuaggaJSResultObject) => callback(result.codeResult.code || '');

                    this._scanner.onDetected(this._detectCallback);

                    this._scanner.start();

                    // TODO: draw in scan area target - "debug" option only works if library itself is built in development
                }
            );
        }
        catch(ex) {
            console.error(ex);
            this.stop();
            return Promise.reject();
        }

        return Promise.resolve();
    }

    public stop(): void {
        if(this._detectCallback) {
            this._scanner.offDetected(this._detectCallback);
        }

        this._scanner.stop();
    }
}

export const BarcodeService = _BarcodeService;