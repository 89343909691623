import { bind, React, NavLink, _ } from 'Imports';

import {
    Button,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    Typography,
    TextValidator,
    ValidatorForm,
    MenuItem,
    CardMedia,
} from 'MaterialUIComponents';

import { IdentityService, IIdentityServiceInjectedProps } from '$State/IdentityFreezerService';
import { InstallerUserFleetResponse, LoginUserRequest } from '$Generated/api';
import { SelectValidator } from 'react-material-ui-form-validator';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

interface ILoginBaseProps {
    onLogin: () => void;
}

type ILoginProps = ILoginBaseProps & IIdentityServiceInjectedProps & IConfigServiceInjectedProps;

interface ILoginState {
    user: LoginUserRequest;
    fleets?: InstallerUserFleetResponse[];
    selectedFleetId: string;
    errorMsg: string;
    visiblePassword: string;
}

const styles = require('./Login.scss') as {
    main: string;
    content: string;
    errorMessages: string;
    actions: string;
    actionText: string;
    logo: string;
    loginButton: string;
    titleFont: string;
};

class _Login extends React.Component<ILoginProps, ILoginState> {
    state: ILoginState = {
        user: {
            username: '',
            password: '',
        },
        fleets: undefined,
        selectedFleetId: '',
        errorMsg: '',
        visiblePassword: '',
    };

    @bind
    async login() {
        const { username, password } = this.state.user;
        this.setState({ errorMsg: '' });

        try {
            await this.props.identity.login({
                username: username,
                password: password,
            });

            const { loginResult } = this.props.identity.getState();
            if (!loginResult || loginResult.error) {
                throw 'Login failed';
            }

            const fleets = loginResult.data?.fleets;

            this.setState({
                fleets: fleets,
            });

            // auto-select if only one fleet available, but still require the installer to explicitly set it
            if (fleets?.length === 1) {
                this.setState({
                    selectedFleetId: fleets[0].id?.toString() || '',
                });
            }
        } catch (ex) {
            ex.text()
                .then(function (text: string) {
                    return text;
                })
                .then((error: string) => {
                    this.setState({ errorMsg: error });
                });
        }
    }

    @bind
    async setFleet() {
        const { selectedFleetId } = this.state;
        this.setState({ errorMsg: '' });

        try {
            if (!selectedFleetId) {
                throw 'Invalid fleet';
            }

            await this.props.identity.setFleet(Number(selectedFleetId));

            this.props.onLogin();
        } catch {
            // TODO: should a set fleet exception reset the entire login process...?
            this.setState({
                errorMsg: 'There was an error setting your fleet.',
            });
        }
    }

    async handleUserFieldChange(field: 'username' | 'password', event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const newUser: LoginUserRequest = _.clone(this.state.user);
        let newPassword;
        const passwordLength = newUser.password?.length ?? 0;
        const inputValue = event.target.value;

        if (field == 'password') {
            if (inputValue.length == 0) {
                newUser[field] = '';
                this.setState({ visiblePassword: '' });
            } else if (inputValue.length == passwordLength - 1) {
                newPassword = newUser.password?.substring(0, passwordLength - 1) ?? '';
                newUser[field] = newPassword;
                this.setState({ visiblePassword: '*'.repeat(newPassword?.length) });
            } else if (inputValue.length > passwordLength + 1 || inputValue.length < passwordLength - 1) {
                newUser[field] = inputValue;
                this.setState({ visiblePassword: '*'.repeat(inputValue.length) });
            } else {
                newUser[field] = newUser.password + inputValue.slice(-1);
                this.setState({ visiblePassword: '*'.repeat(inputValue.length) });
            }
        } else {
            newUser[field] = event.target.value;
        }

        this.setState({ user: newUser });
    }

    handleFleetChange(event: React.ChangeEvent<HTMLSelectElement>): void {
        this.setState({
            selectedFleetId: event.target.value || '',
        });
    }

    render(): JSX.Element {
        const { errorMsg, fleets, selectedFleetId, user } = this.state;
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        return (
            <div className={styles.main}>
                <Card className={styles.content}>
                    <CardHeader className={styles.titleFont} title="Installer Login" titleTypographyProps={{ variant: 'h2' }} />

                    {!fleets && (
                        <ValidatorForm ref="form" onSubmit={this.login}>
                            <CardContent>
                                {errorMsg && <Typography className={styles.errorMessages}>{errorMsg}</Typography>}

                                <TextValidator
                                    name="username"
                                    label="Username"
                                    type="text"
                                    margin="normal"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={(e: any) => this.handleUserFieldChange('username', e)}
                                    value={user.username}
                                    validators={['required']}
                                    errorMessages={['Username is required']}
                                    required={true}
                                />

                                <TextValidator
                                    name="password"
                                    label="Password"
                                    type="password"
                                    margin="normal"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={(e: any) => this.handleUserFieldChange('password', e)}
                                    value={this.state.visiblePassword}
                                    validators={['required']}
                                    errorMessages={['Password is required']}
                                    required={true}
                                />
                            </CardContent>

                            <CardActions className={styles.actions}>
                                <Button variant="contained" type="submit" classes={{ root: styles.loginButton }}>
                                    Log In
                                </Button>

                                <NavLink className={styles.actionText} exact={true} to="/ForgotPassword">
                                    Forgot Password?
                                </NavLink>
                            </CardActions>
                        </ValidatorForm>
                    )}

                    {fleets && (
                        <ValidatorForm ref="form" onSubmit={this.setFleet}>
                            <CardContent>
                                {errorMsg && <Typography className={styles.errorMessages}>{errorMsg}</Typography>}

                                <SelectValidator
                                    name="fleetId"
                                    label="Select Fleet"
                                    margin="normal"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={(e: any) => this.handleFleetChange(e)}
                                    value={selectedFleetId}
                                    validators={['required']}
                                    errorMessages={['Fleet is required']}
                                    required={true}
                                    style={{ textAlign: 'left' }}
                                >
                                    {fleets.map((x) => (
                                        <MenuItem key={x.id} value={x.id}>
                                            {x.name}
                                        </MenuItem>
                                    ))}
                                </SelectValidator>
                            </CardContent>

                            <CardActions className={styles.actions}>
                                <Button variant="contained" onClick={this.setFleet} classes={{ contained: styles.loginButton }}>
                                    Set Fleet
                                </Button>
                            </CardActions>
                        </ValidatorForm>
                    )}

                    <CardMedia
                        alt="VideoProtects logo"
                        className={styles.logo}
                        component="img"
                        image={GetImageUrl(currentImagesBucket ? currentImagesBucket : '', currentRegion ? currentRegion : '', 'logo.png')}
                    />
                </Card>
            </div>
        );
    }
}

export const Login = IdentityService.inject(ConfigService.inject(_Login));
