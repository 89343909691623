/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface Assembly {
    "definedTypes"?: Array<TypeInfo>;
    "exportedTypes"?: Array<Type>;
    "codeBase"?: string;
    "entryPoint"?: MethodInfo;
    "fullName"?: string;
    "imageRuntimeVersion"?: string;
    "isDynamic"?: boolean;
    "location"?: string;
    "reflectionOnly"?: boolean;
    "isCollectible"?: boolean;
    "isFullyTrusted"?: boolean;
    "customAttributes"?: Array<CustomAttributeData>;
    "escapedCodeBase"?: string;
    "manifestModule"?: Module;
    "modules"?: Array<Module>;
    "globalAssemblyCache"?: boolean;
    "hostContext"?: number;
    "securityRuleSet"?: SecurityRuleSet;
}

export type CallingConventions = "Standard" | "VarArgs" | "Any" | "HasThis" | "ExplicitThis";

export interface CameraDeviceResponse {
    "id"?: number;
    "deviceType"?: string;
    "serialNumber"?: string;
    "imei"?: string;
    "iccid"?: string;
    "lastHeartbeatTime"?: Date;
    "deviceStatus"?: CameraDeviceStatusEnum;
    "currentPairing"?: VehicleCameraPairingResponse;
}

export type CameraDeviceStatusEnum = "Active" | "Suspended" | "Retired" | "Expired" | "New" | "PendingSignature" | "Pairing" | "Disabled";

export interface CameraRequestResponse {
    "id"?: number;
    "cameraDeviceId"?: number;
    "requestDate"?: Date;
    "commandPayloadJson"?: string;
    "completionDate"?: Date;
    "uploadStrategy"?: string;
    "command"?: string;
    "requestStepId"?: VideoEventPipelineStepEnum;
    "requestStepDescriptionId"?: number;
    "claimToken"?: string;
    "requestedBy"?: string;
    "cameraRequestTypeId"?: CameraRequestTypeEnum;
    "requestStepDescription"?: VideoEventPipelineStepDescriptionResponse;
}

export type CameraRequestTypeEnum = "Log" | "Picture" | "Video";

export interface ClientConfigResponse {
    "imagesBucket"?: string;
    "imagesBucketRegion"?: string;
    "integrationPlatform"?: string;
    "deployEnvironment"?: string;
    "apmServiceUrl"?: string;
    "apmServiceName"?: string;
}

export interface ConstructorInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "memberType"?: MemberTypes;
}

export interface CreatePairingRequest {
    "fleetId"?: number;
    "cameraDeviceId"?: number;
    "vehicleName"?: string;
    "vehicleVin"?: string;
    "telematicProviderId"?: TelematicProviderIdEnum;
    "integrationPartnerVehicleId"?: string;
    "telematicType"?: string;
    "telematicSerialNumber"?: string;
    "isGpsTrackingEnabled"?: boolean;
}

export interface CustomAttributeData {
    "attributeType"?: Type;
    "constructor"?: ConstructorInfo;
    "constructorArguments"?: Array<CustomAttributeTypedArgument>;
    "namedArguments"?: Array<CustomAttributeNamedArgument>;
}

export interface CustomAttributeNamedArgument {
    "memberInfo"?: MemberInfo;
    "typedValue"?: CustomAttributeTypedArgument;
    "memberName"?: string;
    "isField"?: boolean;
}

export interface CustomAttributeTypedArgument {
    "argumentType"?: Type;
}

export type EventAttributes = "None" | "SpecialName" | "RTSpecialName" | "ReservedMask";

export interface EventInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "attributes"?: EventAttributes;
    "isSpecialName"?: boolean;
    "addMethod"?: MethodInfo;
    "removeMethod"?: MethodInfo;
    "raiseMethod"?: MethodInfo;
    "isMulticast"?: boolean;
    "eventHandlerType"?: Type;
}

export interface Exception {
    "targetSite"?: MethodBase;
    "message"?: string;
    "data"?: any;
    "innerException"?: Exception;
    "helpLink"?: string;
    "source"?: string;
    "hResult"?: number;
    "stackTrace"?: string;
}

export type FieldAttributes = "PrivateScope" | "Private" | "FamANDAssem" | "Assembly" | "Family" | "FamORAssem" | "Public" | "FieldAccessMask" | "Static" | "InitOnly" | "Literal" | "NotSerialized" | "HasFieldRVA" | "SpecialName" | "RTSpecialName" | "HasFieldMarshal" | "PinvokeImpl" | "HasDefault" | "ReservedMask";

export interface FieldInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "attributes"?: FieldAttributes;
    "fieldType"?: Type;
    "isInitOnly"?: boolean;
    "isLiteral"?: boolean;
    "isNotSerialized"?: boolean;
    "isPinvokeImpl"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "fieldHandle"?: RuntimeFieldHandle;
}

export type FileUploadExtensionEnum = "Mp4" | "Jpeg" | "Mp5" | "Txt" | "Webm" | "Ogv" | "Png";

export type FileUploadTypeEnum = "FrontVideo" | "RearVideo" | "SideVideo" | "Log" | "AltVideo";

export type GenericParameterAttributes = "None" | "Covariant" | "Contravariant" | "VarianceMask" | "ReferenceTypeConstraint" | "NotNullableValueTypeConstraint" | "DefaultConstructorConstraint" | "SpecialConstraintMask";

export interface HealthCheckResponse {
}

export interface HealthCheckResponseResponseBase {
    "success"?: boolean;
    "error"?: Exception;
    "totalCount"?: number;
    "data"?: HealthCheckResponse;
}

export interface ICustomAttributeProvider {
}

export interface InstallerUserFleetResponse {
    "id"?: number;
    "name"?: string;
}

export interface InstallerUserResponse {
    "firstName"?: string;
    "lastName"?: string;
    "email"?: string;
    "roles"?: Array<string>;
    "currentFleetId"?: number;
    "fleets"?: Array<InstallerUserFleetResponse>;
}

export interface IntPtr {
}

export type LayoutKind = "Sequential" | "Explicit" | "Auto";

export interface LoginUserRequest {
    "username"?: string;
    "password"?: string;
    "token"?: string;
}

export interface MemberInfo {
    "memberType"?: MemberTypes;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "name"?: string;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
}

export type MemberTypes = "Constructor" | "Event" | "Field" | "Method" | "Property" | "TypeInfo" | "Custom" | "NestedType" | "All";

export type MethodAttributes = "ReuseSlot" | "PrivateScope" | "Private" | "FamANDAssem" | "Assembly" | "Family" | "FamORAssem" | "Public" | "MemberAccessMask" | "UnmanagedExport" | "Static" | "Final" | "Virtual" | "HideBySig" | "NewSlot" | "VtableLayoutMask" | "CheckAccessOnOverride" | "Abstract" | "SpecialName" | "RTSpecialName" | "PinvokeImpl" | "HasSecurity" | "RequireSecObject" | "ReservedMask";

export interface MethodBase {
    "memberType"?: MemberTypes;
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
}

export type MethodImplAttributes = "IL" | "Managed" | "Native" | "OPTIL" | "Runtime" | "CodeTypeMask" | "Unmanaged" | "ManagedMask" | "NoInlining" | "ForwardRef" | "Synchronized" | "NoOptimization" | "PreserveSig" | "AggressiveInlining" | "AggressiveOptimization" | "InternalCall" | "MaxMethodImplVal";

export interface MethodInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "attributes"?: MethodAttributes;
    "methodImplementationFlags"?: MethodImplAttributes;
    "callingConvention"?: CallingConventions;
    "isAbstract"?: boolean;
    "isConstructor"?: boolean;
    "isFinal"?: boolean;
    "isHideBySig"?: boolean;
    "isSpecialName"?: boolean;
    "isStatic"?: boolean;
    "isVirtual"?: boolean;
    "isAssembly"?: boolean;
    "isFamily"?: boolean;
    "isFamilyAndAssembly"?: boolean;
    "isFamilyOrAssembly"?: boolean;
    "isPrivate"?: boolean;
    "isPublic"?: boolean;
    "isConstructedGenericMethod"?: boolean;
    "isGenericMethod"?: boolean;
    "isGenericMethodDefinition"?: boolean;
    "containsGenericParameters"?: boolean;
    "methodHandle"?: RuntimeMethodHandle;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "memberType"?: MemberTypes;
    "returnParameter"?: ParameterInfo;
    "returnType"?: Type;
    "returnTypeCustomAttributes"?: ICustomAttributeProvider;
}

export interface Module {
    "assembly"?: Assembly;
    "fullyQualifiedName"?: string;
    "name"?: string;
    "mdStreamVersion"?: number;
    "moduleVersionId"?: string;
    "scopeName"?: string;
    "moduleHandle"?: ModuleHandle;
    "customAttributes"?: Array<CustomAttributeData>;
    "metadataToken"?: number;
}

export interface ModuleHandle {
    "mdStreamVersion"?: number;
}

export type ParameterAttributes = "None" | "In" | "Out" | "Lcid" | "Retval" | "Optional" | "HasDefault" | "HasFieldMarshal" | "Reserved3" | "Reserved4" | "ReservedMask";

export interface ParameterInfo {
    "attributes"?: ParameterAttributes;
    "member"?: MemberInfo;
    "name"?: string;
    "parameterType"?: Type;
    "position"?: number;
    "isIn"?: boolean;
    "isLcid"?: boolean;
    "isOptional"?: boolean;
    "isOut"?: boolean;
    "isRetval"?: boolean;
    "hasDefaultValue"?: boolean;
    "customAttributes"?: Array<CustomAttributeData>;
    "metadataToken"?: number;
}

export interface ProcessControlMessage {
    "messageId"?: string;
    "queueName"?: string;
    "processId"?: string;
    "tenantId"?: string;
    "targetDataKey"?: string;
    "priority"?: number;
    "processData"?: { [key: string]: string; };
}

export type PropertyAttributes = "None" | "SpecialName" | "RTSpecialName" | "HasDefault" | "Reserved2" | "Reserved3" | "Reserved4" | "ReservedMask";

export interface PropertyInfo {
    "name"?: string;
    "declaringType"?: Type;
    "reflectedType"?: Type;
    "module"?: Module;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "memberType"?: MemberTypes;
    "propertyType"?: Type;
    "attributes"?: PropertyAttributes;
    "isSpecialName"?: boolean;
    "canRead"?: boolean;
    "canWrite"?: boolean;
    "getMethod"?: MethodInfo;
    "setMethod"?: MethodInfo;
}

export interface RuntimeFieldHandle {
    "value"?: IntPtr;
}

export interface RuntimeMethodHandle {
    "value"?: IntPtr;
}

export interface RuntimeTypeHandle {
    "value"?: IntPtr;
}

export type SecurityRuleSet = "None" | "Level1" | "Level2";

export interface SpeedGraphPointResponse {
    "id"?: number;
    "speed"?: number;
    "time"?: Date;
    "videoEventId"?: number;
}

export interface StructLayoutAttribute {
    "value"?: LayoutKind;
}

export interface TelematicDeviceResponse {
    "id"?: number;
    "serialNumber"?: string;
    "deviceId"?: string;
    "name"?: string;
    "type"?: string;
    "providerId"?: TelematicProviderIdEnum;
    "providerName"?: string;
    "location"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "ignitionOn"?: boolean;
    "refreshDate"?: Date;
}

export type TelematicProviderIdEnum = "Geotab" | "Striker" | "Mitac";

export interface TokenDataRequest {
    "username"?: string;
    "token"?: string;
}

export interface Type {
    "name"?: string;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "isInterface"?: boolean;
    "memberType"?: MemberTypes;
    "namespace"?: string;
    "assemblyQualifiedName"?: string;
    "fullName"?: string;
    "assembly"?: Assembly;
    "module"?: Module;
    "isNested"?: boolean;
    "declaringType"?: Type;
    "declaringMethod"?: MethodBase;
    "reflectedType"?: Type;
    "underlyingSystemType"?: Type;
    "isTypeDefinition"?: boolean;
    "isArray"?: boolean;
    "isByRef"?: boolean;
    "isPointer"?: boolean;
    "isConstructedGenericType"?: boolean;
    "isGenericParameter"?: boolean;
    "isGenericTypeParameter"?: boolean;
    "isGenericMethodParameter"?: boolean;
    "isGenericType"?: boolean;
    "isGenericTypeDefinition"?: boolean;
    "isSZArray"?: boolean;
    "isVariableBoundArray"?: boolean;
    "isByRefLike"?: boolean;
    "hasElementType"?: boolean;
    "genericTypeArguments"?: Array<Type>;
    "genericParameterPosition"?: number;
    "genericParameterAttributes"?: GenericParameterAttributes;
    "attributes"?: TypeAttributes;
    "isAbstract"?: boolean;
    "isImport"?: boolean;
    "isSealed"?: boolean;
    "isSpecialName"?: boolean;
    "isClass"?: boolean;
    "isNestedAssembly"?: boolean;
    "isNestedFamANDAssem"?: boolean;
    "isNestedFamily"?: boolean;
    "isNestedFamORAssem"?: boolean;
    "isNestedPrivate"?: boolean;
    "isNestedPublic"?: boolean;
    "isNotPublic"?: boolean;
    "isPublic"?: boolean;
    "isAutoLayout"?: boolean;
    "isExplicitLayout"?: boolean;
    "isLayoutSequential"?: boolean;
    "isAnsiClass"?: boolean;
    "isAutoClass"?: boolean;
    "isUnicodeClass"?: boolean;
    "isCOMObject"?: boolean;
    "isContextful"?: boolean;
    "isEnum"?: boolean;
    "isMarshalByRef"?: boolean;
    "isPrimitive"?: boolean;
    "isValueType"?: boolean;
    "isSignatureType"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "structLayoutAttribute"?: StructLayoutAttribute;
    "typeInitializer"?: ConstructorInfo;
    "typeHandle"?: RuntimeTypeHandle;
    "guid"?: string;
    "baseType"?: Type;
    "isSerializable"?: boolean;
    "containsGenericParameters"?: boolean;
    "isVisible"?: boolean;
}

export type TypeAttributes = "NotPublic" | "AutoLayout" | "AnsiClass" | "Class" | "Public" | "NestedPublic" | "NestedPrivate" | "NestedFamily" | "NestedAssembly" | "NestedFamANDAssem" | "NestedFamORAssem" | "VisibilityMask" | "SequentialLayout" | "ExplicitLayout" | "LayoutMask" | "Interface" | "ClassSemanticsMask" | "Abstract" | "Sealed" | "SpecialName" | "RTSpecialName" | "Import" | "Serializable" | "WindowsRuntime" | "UnicodeClass" | "AutoClass" | "CustomFormatClass" | "StringFormatMask" | "HasSecurity" | "ReservedMask" | "BeforeFieldInit" | "CustomFormatMask";

export interface TypeInfo {
    "name"?: string;
    "customAttributes"?: Array<CustomAttributeData>;
    "isCollectible"?: boolean;
    "metadataToken"?: number;
    "isInterface"?: boolean;
    "memberType"?: MemberTypes;
    "namespace"?: string;
    "assemblyQualifiedName"?: string;
    "fullName"?: string;
    "assembly"?: Assembly;
    "module"?: Module;
    "isNested"?: boolean;
    "declaringType"?: Type;
    "declaringMethod"?: MethodBase;
    "reflectedType"?: Type;
    "underlyingSystemType"?: Type;
    "isTypeDefinition"?: boolean;
    "isArray"?: boolean;
    "isByRef"?: boolean;
    "isPointer"?: boolean;
    "isConstructedGenericType"?: boolean;
    "isGenericParameter"?: boolean;
    "isGenericTypeParameter"?: boolean;
    "isGenericMethodParameter"?: boolean;
    "isGenericType"?: boolean;
    "isGenericTypeDefinition"?: boolean;
    "isSZArray"?: boolean;
    "isVariableBoundArray"?: boolean;
    "isByRefLike"?: boolean;
    "hasElementType"?: boolean;
    "genericTypeArguments"?: Array<Type>;
    "genericParameterPosition"?: number;
    "genericParameterAttributes"?: GenericParameterAttributes;
    "attributes"?: TypeAttributes;
    "isAbstract"?: boolean;
    "isImport"?: boolean;
    "isSealed"?: boolean;
    "isSpecialName"?: boolean;
    "isClass"?: boolean;
    "isNestedAssembly"?: boolean;
    "isNestedFamANDAssem"?: boolean;
    "isNestedFamily"?: boolean;
    "isNestedFamORAssem"?: boolean;
    "isNestedPrivate"?: boolean;
    "isNestedPublic"?: boolean;
    "isNotPublic"?: boolean;
    "isPublic"?: boolean;
    "isAutoLayout"?: boolean;
    "isExplicitLayout"?: boolean;
    "isLayoutSequential"?: boolean;
    "isAnsiClass"?: boolean;
    "isAutoClass"?: boolean;
    "isUnicodeClass"?: boolean;
    "isCOMObject"?: boolean;
    "isContextful"?: boolean;
    "isEnum"?: boolean;
    "isMarshalByRef"?: boolean;
    "isPrimitive"?: boolean;
    "isValueType"?: boolean;
    "isSignatureType"?: boolean;
    "isSecurityCritical"?: boolean;
    "isSecuritySafeCritical"?: boolean;
    "isSecurityTransparent"?: boolean;
    "structLayoutAttribute"?: StructLayoutAttribute;
    "typeInitializer"?: ConstructorInfo;
    "typeHandle"?: RuntimeTypeHandle;
    "guid"?: string;
    "baseType"?: Type;
    "isSerializable"?: boolean;
    "containsGenericParameters"?: boolean;
    "isVisible"?: boolean;
    "genericTypeParameters"?: Array<Type>;
    "declaredConstructors"?: Array<ConstructorInfo>;
    "declaredEvents"?: Array<EventInfo>;
    "declaredFields"?: Array<FieldInfo>;
    "declaredMembers"?: Array<MemberInfo>;
    "declaredMethods"?: Array<MethodInfo>;
    "declaredNestedTypes"?: Array<TypeInfo>;
    "declaredProperties"?: Array<PropertyInfo>;
    "implementedInterfaces"?: Array<Type>;
}

export interface UserResponse {
    "id"?: string;
    "integrationProviderSourceId"?: string;
    "displayName"?: string;
    "email"?: string;
}

export interface VehicleCameraPairingResponse {
    "id"?: number;
    "cameraId"?: number;
    "isGpsTrackingEnabled"?: boolean;
    "fleetId"?: number;
    "clientName"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
    "camera"?: CameraDeviceResponse;
    "vehicle"?: VehicleResponse;
}

export interface VehicleResponse {
    "id"?: number;
    "name"?: string;
    "vin"?: string;
    "integrationPartnerVehicleId"?: string;
    "fleetId"?: number;
    "telematicDevice"?: TelematicDeviceResponse;
}

export interface VideoAssetResponse {
    "id"?: number;
    "creationDate"?: Date;
    "fileUploadExtensionId"?: FileUploadExtensionEnum;
    "fileUploadTypeId"?: FileUploadTypeEnum;
    "fileUploadTypeDisplayName"?: string;
    "fileUploadId"?: number;
    "type"?: VideoAssetTypeEnum;
    "videoAssetStorageId"?: VideoAssetStorageEnum;
    "storageDetailsJson"?: string;
    "status"?: VideoAssetStatusEnum;
    "aspectRatio"?: string;
}

export type VideoAssetStatusEnum = "Idle" | "CheckOut" | "Generating" | "CheckIn" | "Success" | "Failed";

export type VideoAssetStorageEnum = "GeotabApi" | "GcpFs" | "Controller";

export type VideoAssetTypeEnum = "Upload" | "Full" | "Thumbnail" | "Transcode" | "Preview";

export interface VideoEventActionResponse {
    "id"?: number;
    "actionType"?: VideoEventActionTypeEnum;
    "actionDescription"?: string;
    "actionDate"?: Date;
    "createdBy"?: UserResponse;
}

export type VideoEventActionTypeEnum = "ScoreChanged" | "EventFlagged" | "VideoDownloaded" | "EventDismissed" | "EventCompleted" | "EventReopened" | "PlayedVideo" | "EventStatusChanged" | "CommentAdded" | "CommentDeleted" | "CommentEdited" | "ActionTakenStatus" | "DriverClickedLink" | "DriverStartedWatchingVideo" | "DriverCompletedWatchingVideo" | "EventUnflagged" | "EmailedDriver" | "TextedDriver" | "VideoRecallPromoted" | "DriverChanged" | "VideosWatched";

export type VideoEventAdminResolutionEnum = "ReviewedWithDriver" | "DisciplinedDriver" | "SubmittedToInsurance" | "SavedForFutureReference" | "TerminatedDriver" | "Other";

export type VideoEventInitiatorEnum = "GeotabEventETLProcessor" | "Manual" | "IntegrationTest";

export interface VideoEventPipelineStepDescriptionResponse {
    "id"?: number;
    "displayName"?: string;
    "sysName"?: string;
    "description"?: string;
}

export type VideoEventPipelineStepEnum = "Idle" | "CameraRequest" | "UploadWait" | "VideoProcessing" | "Success" | "Failed" | "CameraPrepare" | "PreparedIdle" | "UploadedIdle" | "CameraDownload" | "DownloadedIdle" | "Purged";

export interface VideoEventResponse {
    "id"?: number;
    "geotabEventId"?: string;
    "videoEventInitiator"?: VideoEventInitiatorEnum;
    "videoEventType"?: Array<number>;
    "videoEventTypePairings"?: Array<VideoEventTypePairingResponse>;
    "driverName"?: string;
    "driverId"?: string;
    "driverTelematicUserId"?: string;
    "driverEmail"?: string;
    "eventStartDate"?: Date;
    "eventEndDate"?: Date;
    "eventDurationSeconds"?: number;
    "location"?: string;
    "addressStreetNumber"?: string;
    "addressRegion"?: string;
    "addressStreetName"?: string;
    "addressPostalCode"?: string;
    "addressCityName"?: string;
    "kphStart"?: number;
    "kphEnd"?: number;
    "vehicleDescription"?: string;
    "integrationPartnerVehicleId"?: string;
    "licensePlateNumber"?: string;
    "licensePlateState"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "workflowStep"?: VideoEventPipelineStepEnum;
    "workflowStepDescription"?: string;
    "requestedBy"?: string;
    "requestedOn"?: Date;
    "videoAsset"?: Array<VideoAssetResponse>;
    "telematicDeviceId"?: number;
    "telematicDeviceSourceId"?: string;
    "telematicDeviceName"?: string;
    "videoEventTypeString"?: string;
    "actionHistory"?: Array<VideoEventActionResponse>;
    "isFlagged"?: boolean;
    "flaggedByUsers"?: Array<UserResponse>;
    "score"?: number;
    "videoEventStatus"?: VideoEventWorkflowStatusIdEnum;
    "adminResolution"?: VideoEventAdminResolutionEnum;
    "cameraRequest"?: CameraRequestResponse;
    "speedGraphPoints"?: Array<SpeedGraphPointResponse>;
    "eventJson"?: string;
    "cameraDeviceSerialNumber"?: string;
}

export interface VideoEventTypePairingResponse {
    "videoEventTypeString"?: string;
    "videoEventTypeIconName"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
}

export type VideoEventWorkflowStatusIdEnum = "New" | "In_Progress" | "Dismissed" | "Completed" | "Sent_To_Driver" | "No_Response" | "Driver_Accepted" | "Driver_Disputed" | "Deleted";



/**
 * ApiEndpointApi - fetch parameter creator
 */
export const ApiEndpointApiFetchParamCreator = {
    /** 
     */
    apiHelloGet(options?: any): FetchArgs {
        const baseUrl = `/api/hello`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProcessControlMessageByName`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApiEndpointApi - functional programming interface
 */
export const ApiEndpointApiFp = {
    /** 
     */
    apiHelloGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiHelloGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: { "body"?: ProcessControlMessage;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiProcessControlMessageByNamePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApiEndpointApi - object-oriented interface
 */
export class ApiEndpointApi extends BaseAPI {
    /** 
     */
    apiHelloGet(options?: any) {
        return ApiEndpointApiFp.apiHelloGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
        return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ApiEndpointApi - factory interface
 */
export const ApiEndpointApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiHelloGet(options?: any) {
            return ApiEndpointApiFp.apiHelloGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
            return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * ConfigApi - fetch parameter creator
 */
export const ConfigApiFetchParamCreator = {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/config/getClientConfig`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ConfigApi - functional programming interface
 */
export const ConfigApiFp = {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ClientConfigResponse> {
        const fetchArgs = ConfigApiFetchParamCreator.apiV1ConfigGetClientConfigGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ConfigApi - object-oriented interface
 */
export class ConfigApi extends BaseAPI {
    /** 
     */
    apiV1ConfigGetClientConfigGet(options?: any) {
        return ConfigApiFp.apiV1ConfigGetClientConfigGet(options)(this.fetch, this.basePath);
    }
};

/**
 * ConfigApi - factory interface
 */
export const ConfigApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1ConfigGetClientConfigGet(options?: any) {
            return ConfigApiFp.apiV1ConfigGetClientConfigGet(options)(fetch, basePath);
        },
    };
};


/**
 * DeviceAssociationApi - fetch parameter creator
 */
export const DeviceAssociationApiFetchParamCreator = {
    /** 
     * Gets list of cameras in the user&#39;s fleet that are not paired with a vehicle
     */
    apiV1DeviceAssociationCamerasGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/DeviceAssociation/Cameras`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Unpairs the specified vehicle/camera pairing
     * @param id The ID of the pair
     */
    apiV1DeviceAssociationDevicePairIdDelete(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1DeviceAssociationDevicePairIdDelete");
        }
        const baseUrl = `/api/v1/DeviceAssociation/DevicePair/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Retrieve the specified vehicle/camera pairing
     * @param id The ID of the pair
     */
    apiV1DeviceAssociationDevicePairIdGet(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1DeviceAssociationDevicePairIdGet");
        }
        const baseUrl = `/api/v1/DeviceAssociation/DevicePair/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a vehicle/camera pairing
     * @param body 
     */
    apiV1DeviceAssociationDevicePairPost(params: {  "body"?: CreatePairingRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/DeviceAssociation/DevicePair`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Retrieve vehicle/camera pairings created by the logged in user
     */
    apiV1DeviceAssociationDevicePairsByUserGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/DeviceAssociation/DevicePairsByUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets list of vehicles in the user&#39;s fleet that are not paired with a camera device
     */
    apiV1DeviceAssociationVehiclesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/DeviceAssociation/Vehicles`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DeviceAssociationApi - functional programming interface
 */
export const DeviceAssociationApiFp = {
    /** 
     * Gets list of cameras in the user&#39;s fleet that are not paired with a vehicle
     */
    apiV1DeviceAssociationCamerasGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CameraDeviceResponse>> {
        const fetchArgs = DeviceAssociationApiFetchParamCreator.apiV1DeviceAssociationCamerasGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Unpairs the specified vehicle/camera pairing
     * @param id The ID of the pair
     */
    apiV1DeviceAssociationDevicePairIdDelete(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeviceAssociationApiFetchParamCreator.apiV1DeviceAssociationDevicePairIdDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Retrieve the specified vehicle/camera pairing
     * @param id The ID of the pair
     */
    apiV1DeviceAssociationDevicePairIdGet(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VehicleCameraPairingResponse> {
        const fetchArgs = DeviceAssociationApiFetchParamCreator.apiV1DeviceAssociationDevicePairIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a vehicle/camera pairing
     * @param body 
     */
    apiV1DeviceAssociationDevicePairPost(params: { "body"?: CreatePairingRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DeviceAssociationApiFetchParamCreator.apiV1DeviceAssociationDevicePairPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Retrieve vehicle/camera pairings created by the logged in user
     */
    apiV1DeviceAssociationDevicePairsByUserGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VehicleCameraPairingResponse>> {
        const fetchArgs = DeviceAssociationApiFetchParamCreator.apiV1DeviceAssociationDevicePairsByUserGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets list of vehicles in the user&#39;s fleet that are not paired with a camera device
     */
    apiV1DeviceAssociationVehiclesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VehicleResponse>> {
        const fetchArgs = DeviceAssociationApiFetchParamCreator.apiV1DeviceAssociationVehiclesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DeviceAssociationApi - object-oriented interface
 */
export class DeviceAssociationApi extends BaseAPI {
    /** 
     * Gets list of cameras in the user&#39;s fleet that are not paired with a vehicle
     */
    apiV1DeviceAssociationCamerasGet(options?: any) {
        return DeviceAssociationApiFp.apiV1DeviceAssociationCamerasGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Unpairs the specified vehicle/camera pairing
     * @param id The ID of the pair
     */
    apiV1DeviceAssociationDevicePairIdDelete(params: {  "id": number; }, options?: any) {
        return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairIdDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Retrieve the specified vehicle/camera pairing
     * @param id The ID of the pair
     */
    apiV1DeviceAssociationDevicePairIdGet(params: {  "id": number; }, options?: any) {
        return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a vehicle/camera pairing
     * @param body 
     */
    apiV1DeviceAssociationDevicePairPost(params: {  "body"?: CreatePairingRequest; }, options?: any) {
        return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Retrieve vehicle/camera pairings created by the logged in user
     */
    apiV1DeviceAssociationDevicePairsByUserGet(options?: any) {
        return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairsByUserGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Gets list of vehicles in the user&#39;s fleet that are not paired with a camera device
     */
    apiV1DeviceAssociationVehiclesGet(options?: any) {
        return DeviceAssociationApiFp.apiV1DeviceAssociationVehiclesGet(options)(this.fetch, this.basePath);
    }
};

/**
 * DeviceAssociationApi - factory interface
 */
export const DeviceAssociationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets list of cameras in the user&#39;s fleet that are not paired with a vehicle
         */
        apiV1DeviceAssociationCamerasGet(options?: any) {
            return DeviceAssociationApiFp.apiV1DeviceAssociationCamerasGet(options)(fetch, basePath);
        },
        /** 
         * Unpairs the specified vehicle/camera pairing
         * @param id The ID of the pair
         */
        apiV1DeviceAssociationDevicePairIdDelete(params: {  "id": number; }, options?: any) {
            return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairIdDelete(params, options)(fetch, basePath);
        },
        /** 
         * Retrieve the specified vehicle/camera pairing
         * @param id The ID of the pair
         */
        apiV1DeviceAssociationDevicePairIdGet(params: {  "id": number; }, options?: any) {
            return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Create a vehicle/camera pairing
         * @param body 
         */
        apiV1DeviceAssociationDevicePairPost(params: {  "body"?: CreatePairingRequest; }, options?: any) {
            return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairPost(params, options)(fetch, basePath);
        },
        /** 
         * Retrieve vehicle/camera pairings created by the logged in user
         */
        apiV1DeviceAssociationDevicePairsByUserGet(options?: any) {
            return DeviceAssociationApiFp.apiV1DeviceAssociationDevicePairsByUserGet(options)(fetch, basePath);
        },
        /** 
         * Gets list of vehicles in the user&#39;s fleet that are not paired with a camera device
         */
        apiV1DeviceAssociationVehiclesGet(options?: any) {
            return DeviceAssociationApiFp.apiV1DeviceAssociationVehiclesGet(options)(fetch, basePath);
        },
    };
};


/**
 * HealthApi - fetch parameter creator
 */
export const HealthApiFetchParamCreator = {
    /** 
     * Allow external (non-local dev) actors to verify if application is online  nginx redirect from /api/v1/health/installapp will land here
     */
    apiV1HealthInstallappGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Health/installapp`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * HealthApi - functional programming interface
 */
export const HealthApiFp = {
    /** 
     * Allow external (non-local dev) actors to verify if application is online  nginx redirect from /api/v1/health/installapp will land here
     */
    apiV1HealthInstallappGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HealthCheckResponseResponseBase> {
        const fetchArgs = HealthApiFetchParamCreator.apiV1HealthInstallappGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * HealthApi - object-oriented interface
 */
export class HealthApi extends BaseAPI {
    /** 
     * Allow external (non-local dev) actors to verify if application is online  nginx redirect from /api/v1/health/installapp will land here
     */
    apiV1HealthInstallappGet(options?: any) {
        return HealthApiFp.apiV1HealthInstallappGet(options)(this.fetch, this.basePath);
    }
};

/**
 * HealthApi - factory interface
 */
export const HealthApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Allow external (non-local dev) actors to verify if application is online  nginx redirect from /api/v1/health/installapp will land here
         */
        apiV1HealthInstallappGet(options?: any) {
            return HealthApiFp.apiV1HealthInstallappGet(options)(fetch, basePath);
        },
    };
};


/**
 * IdentityApi - fetch parameter creator
 */
export const IdentityApiFetchParamCreator = {
    /** 
     */
    apiV1IdentityCurrentuserGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/currentuser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityForgotPasswordPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/forgotPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityLoginPost(params: {  "body"?: LoginUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/login`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     */
    apiV1IdentityLogoutPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityResetPasswordPost(params: {  "body"?: LoginUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/resetPassword`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentitySetFleetPost(params: {  "body"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/setFleet`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityVerifyPost(params: {  "body"?: LoginUserRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/verify`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityVerifyTokenPost(params: {  "body"?: TokenDataRequest; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/identity/verifyToken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * IdentityApi - functional programming interface
 */
export const IdentityApiFp = {
    /** 
     */
    apiV1IdentityCurrentuserGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InstallerUserResponse> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityCurrentuserGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityForgotPasswordPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityForgotPasswordPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityLoginPost(params: { "body"?: LoginUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InstallerUserResponse> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityLoginPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     */
    apiV1IdentityLogoutPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityLogoutPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityResetPasswordPost(params: { "body"?: LoginUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityResetPasswordPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentitySetFleetPost(params: { "body"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentitySetFleetPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityVerifyPost(params: { "body"?: LoginUserRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityVerifyPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1IdentityVerifyTokenPost(params: { "body"?: TokenDataRequest;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = IdentityApiFetchParamCreator.apiV1IdentityVerifyTokenPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * IdentityApi - object-oriented interface
 */
export class IdentityApi extends BaseAPI {
    /** 
     */
    apiV1IdentityCurrentuserGet(options?: any) {
        return IdentityApiFp.apiV1IdentityCurrentuserGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1IdentityForgotPasswordPost(params: {  "body"?: string; }, options?: any) {
        return IdentityApiFp.apiV1IdentityForgotPasswordPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1IdentityLoginPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1IdentityLoginPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     */
    apiV1IdentityLogoutPost(options?: any) {
        return IdentityApiFp.apiV1IdentityLogoutPost(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1IdentityResetPasswordPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1IdentityResetPasswordPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1IdentitySetFleetPost(params: {  "body"?: number; }, options?: any) {
        return IdentityApiFp.apiV1IdentitySetFleetPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1IdentityVerifyPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
        return IdentityApiFp.apiV1IdentityVerifyPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1IdentityVerifyTokenPost(params: {  "body"?: TokenDataRequest; }, options?: any) {
        return IdentityApiFp.apiV1IdentityVerifyTokenPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * IdentityApi - factory interface
 */
export const IdentityApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiV1IdentityCurrentuserGet(options?: any) {
            return IdentityApiFp.apiV1IdentityCurrentuserGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1IdentityForgotPasswordPost(params: {  "body"?: string; }, options?: any) {
            return IdentityApiFp.apiV1IdentityForgotPasswordPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1IdentityLoginPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1IdentityLoginPost(params, options)(fetch, basePath);
        },
        /** 
         */
        apiV1IdentityLogoutPost(options?: any) {
            return IdentityApiFp.apiV1IdentityLogoutPost(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1IdentityResetPasswordPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1IdentityResetPasswordPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1IdentitySetFleetPost(params: {  "body"?: number; }, options?: any) {
            return IdentityApiFp.apiV1IdentitySetFleetPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1IdentityVerifyPost(params: {  "body"?: LoginUserRequest; }, options?: any) {
            return IdentityApiFp.apiV1IdentityVerifyPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1IdentityVerifyTokenPost(params: {  "body"?: TokenDataRequest; }, options?: any) {
            return IdentityApiFp.apiV1IdentityVerifyTokenPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * VideoEventApi - fetch parameter creator
 */
export const VideoEventApiFetchParamCreator = {
    /** 
     * Poll the test video recalls for the given integrationPartnerVehicleId
     * @param integrationPartnerVehicleId 
     * @param cameraDeviceId 
     */
    apiV1VideoEventVideoTestGet(params: {  "integrationPartnerVehicleId"?: string; "cameraDeviceId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/videoEvent/videoTest`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "integrationPartnerVehicleId": params["integrationPartnerVehicleId"],
            "cameraDeviceId": params["cameraDeviceId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Request a video recall for the given integrationPartnerVehicleIde
     * @param body 
     */
    apiV1VideoEventVideoTestPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/videoEvent/videoTest`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * VideoEventApi - functional programming interface
 */
export const VideoEventApiFp = {
    /** 
     * Poll the test video recalls for the given integrationPartnerVehicleId
     * @param integrationPartnerVehicleId 
     * @param cameraDeviceId 
     */
    apiV1VideoEventVideoTestGet(params: { "integrationPartnerVehicleId"?: string; "cameraDeviceId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VideoEventResponse> {
        const fetchArgs = VideoEventApiFetchParamCreator.apiV1VideoEventVideoTestGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Request a video recall for the given integrationPartnerVehicleIde
     * @param body 
     */
    apiV1VideoEventVideoTestPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = VideoEventApiFetchParamCreator.apiV1VideoEventVideoTestPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * VideoEventApi - object-oriented interface
 */
export class VideoEventApi extends BaseAPI {
    /** 
     * Poll the test video recalls for the given integrationPartnerVehicleId
     * @param integrationPartnerVehicleId 
     * @param cameraDeviceId 
     */
    apiV1VideoEventVideoTestGet(params: {  "integrationPartnerVehicleId"?: string; "cameraDeviceId"?: number; }, options?: any) {
        return VideoEventApiFp.apiV1VideoEventVideoTestGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Request a video recall for the given integrationPartnerVehicleIde
     * @param body 
     */
    apiV1VideoEventVideoTestPost(params: {  "body"?: string; }, options?: any) {
        return VideoEventApiFp.apiV1VideoEventVideoTestPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * VideoEventApi - factory interface
 */
export const VideoEventApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Poll the test video recalls for the given integrationPartnerVehicleId
         * @param integrationPartnerVehicleId 
         * @param cameraDeviceId 
         */
        apiV1VideoEventVideoTestGet(params: {  "integrationPartnerVehicleId"?: string; "cameraDeviceId"?: number; }, options?: any) {
            return VideoEventApiFp.apiV1VideoEventVideoTestGet(params, options)(fetch, basePath);
        },
        /** 
         * Request a video recall for the given integrationPartnerVehicleIde
         * @param body 
         */
        apiV1VideoEventVideoTestPost(params: {  "body"?: string; }, options?: any) {
            return VideoEventApiFp.apiV1VideoEventVideoTestPost(params, options)(fetch, basePath);
        },
    };
};
