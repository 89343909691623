import { bind, React, moment, withRouter, RouteComponentProps } from '../../Imports';

import { Button, Card, CardContent, Grid, IconButton, Typography } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import * as DateFormatter from '$Components/Shared/DateFormatter';
import { VPCardGridField } from '$Components/Shared/VPCardGridField';

import { Page } from '$Components/Shared/Page';
import { PageHeader } from '$Components/Shared/PageHeader';
import { PageContent } from '$Components/Shared/PageContent';
import { PageFooter } from '$Components/Shared/PageFooter';
import { UnpairConfirmDialog } from '$Components/Shared/UnpairConfirmDialog';

import { VehicleCameraPairingResponse } from '$Generated/api';
import { IDevicePairServiceInjectedProps, DevicePairService } from '$State/DevicePairFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

interface IDevicePairListBaseProps {
    onSelectDevicePair: (pairId?: number) => void;
    onUnpairDevicePair: (pairId?: number) => void;
}

type IDevicePairListProps = IDevicePairListBaseProps & IConfigServiceInjectedProps & RouteComponentProps & IDevicePairServiceInjectedProps;

interface IDevicePairListState {
    selectedDevicePairId?: number;
    showUnpairDialog: boolean;
}

const styles = require('./DevicePairList.scss') as {
    devicePair: string;
    unpairIcon: string;
    detailsLink: string;
    noPairs: string;
    emptyText: string;
    newPairButtonLabel: string;
    newPairButtonOutlined: string;
    newPairIcon: string;
    deleteIcon: string;
};

class _DevicePairList extends React.Component<IDevicePairListProps, IDevicePairListState> {
    state: IDevicePairListState = {
        selectedDevicePairId: undefined,
        showUnpairDialog: false,
    };

    @bind
    selectPair(pair: VehicleCameraPairingResponse): void {
        this.props.onSelectDevicePair(pair.id);
    }

    @bind
    createNewPair(): void {
        this.props.history.push('/createpair');
    }

    @bind
    toggleUnpairDialog(devicePairId?: number): void {
        this.setState((state) => {
            return {
                selectedDevicePairId: devicePairId,
                showUnpairDialog: !state.showUnpairDialog,
            };
        });
    }

    render(): JSX.Element {
        const { showUnpairDialog, selectedDevicePairId } = this.state;
        const freezer = this.props.devicePair.getState();
        const pairedDevices = freezer.pairedDevicesResults?.data;

        const isGeotab = this.props.config.getIsIntegrationPlatformGeotab();

        return (
            <Page>
                <PageHeader pageTitle="Paired Devices" />

                <PageContent>
                    <Grid container>
                        {!pairedDevices || !pairedDevices.length ? (
                            <Grid item xs={12}>
                                <div className={styles.noPairs}>
                                    <Typography component="h2" variant="h5" className={styles.emptyText}>
                                        Your pairing list is empty.
                                    </Typography>

                                    <Button
                                        variant="outlined"
                                        onClick={this.createNewPair}
                                        startIcon={<FontAwesomeIcon icon={faPlusCircle} className={styles.newPairIcon} />}
                                        classes={{ outlined: styles.newPairButtonOutlined, root: styles.newPairButtonLabel }}
                                    >
                                        Begin Pairing
                                    </Button>
                                </div>
                            </Grid>
                        ) : (
                            pairedDevices.map((x) => (
                                <Grid item xs={12} lg={6} key={x.id}>
                                    <Card className={styles.devicePair}>
                                        <CardContent>
                                            <Grid container>
                                                <VPCardGridField
                                                    label="Date Paired"
                                                    value={DateFormatter.dateAndTimezone(moment(x.startDate))}
                                                    xs={12}
                                                />

                                                <VPCardGridField
                                                    label={isGeotab ? 'GO Device' : 'Vehicle'}
                                                    value={x.vehicle?.name}
                                                    xs={isGeotab ? 6 : 12}
                                                />

                                                {isGeotab && (
                                                    <VPCardGridField
                                                        label="Device Serial #"
                                                        value={x.vehicle?.telematicDevice?.serialNumber}
                                                        xs={6}
                                                    />
                                                )}

                                                <VPCardGridField label="Camera Serial #" value={x.camera?.serialNumber} xs={6} />

                                                <VPCardGridField
                                                    className={styles.detailsLink}
                                                    onClick={() => this.selectPair(x)}
                                                    value="View Details"
                                                    xs={12}
                                                />
                                            </Grid>

                                            <IconButton
                                                className={styles.unpairIcon}
                                                size={'small'}
                                                onClick={() => this.toggleUnpairDialog(x.id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} className={styles.deleteIcon} />
                                            </IconButton>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </PageContent>

                <PageFooter
                    right={
                        pairedDevices &&
                        pairedDevices.length && (
                            <Button
                                variant="outlined"
                                onClick={this.createNewPair}
                                startIcon={<FontAwesomeIcon icon={faPlusCircle} className={styles.newPairIcon} />}
                                classes={{ outlined: styles.newPairButtonOutlined, root: styles.newPairButtonLabel }}
                            >
                                Pair
                            </Button>
                        )
                    }
                />

                {showUnpairDialog && selectedDevicePairId && (
                    <UnpairConfirmDialog
                        onUnpair={() => {
                            this.props.onUnpairDevicePair(selectedDevicePairId);
                            this.toggleUnpairDialog();
                        }}
                        onCancel={this.toggleUnpairDialog}
                    />
                )}
            </Page>
        );
    }
}

export const DevicePairList = withRouter(ConfigService.inject(DevicePairService.inject(_DevicePairList)));
