import { KnownValidatorComponent } from 'MaterialUIComponents';

export function getErrorMessages(errors: KnownValidatorComponent[]): string[] {
    const errorMessages: string[] = [];

    // expand _all_ errors detected in each input, not just the first
    for(const error of errors) {
        const indivMessages: string[] = error.invalid.map((x) => `${error.props.label}: ${error.props.errorMessages![x]}`);

        errorMessages.push(...indivMessages);
    }

    return errorMessages;
}
