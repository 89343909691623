import { CameraDeviceResponse, CreatePairingRequest, DeviceAssociationApiFactory, VehicleCameraPairingResponse, VehicleResponse } from '$Generated/api';
import { FreezerService, managedAjaxUtil, IAjaxState } from '../Imports';

const InjectedPropName = 'devicePair';

interface IDevicePairState {
    hasError: boolean;
    cameraResults: IAjaxState<CameraDeviceResponse[]>;
    vehicleResults: IAjaxState<VehicleResponse[]>;
    pairedDevicesResults: IAjaxState<VehicleCameraPairingResponse[]>;
    devicePairResult: IAjaxState<VehicleCameraPairingResponse>;
    createPairResult: IAjaxState<void>;
    unpairResult: IAjaxState<void>;
}

class DevicePairFreezerService extends FreezerService<IDevicePairState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                hasError: false,
                cameraResults: managedAjaxUtil.createInitialState(),
                vehicleResults: managedAjaxUtil.createInitialState(),
                pairedDevicesResults: managedAjaxUtil.createInitialState(),
                devicePairResult: managedAjaxUtil.createInitialState(),
                createPairResult: managedAjaxUtil.createInitialState(),
                unpairResult: managedAjaxUtil.createInitialState()
            },
            InjectedPropName
        );
    }

    public async getUnpairedCameras(): Promise<void | CameraDeviceResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'cameraResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                const deviceAssociationApi = DeviceAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceAssociationApi.apiV1DeviceAssociationCamerasGet(params);
            },
            params: {},
            onOk: (data: CameraDeviceResponse[]) => {
                if(data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async getUnpairedVehicles(): Promise<void | VehicleResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'vehicleResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                const deviceAssociationApi = DeviceAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceAssociationApi.apiV1DeviceAssociationVehiclesGet(params);
            },
            params: {},
            onOk: (data: VehicleResponse[]) => {
                if(data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async getPairedDevices(): Promise<void | VehicleCameraPairingResponse[]> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'pairedDevicesResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                const deviceAssociationApi = DeviceAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceAssociationApi.apiV1DeviceAssociationDevicePairsByUserGet(params);
            },
            params: {},
            onOk: (data: VehicleCameraPairingResponse[]) => {
                if(data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async getDevicePair(pairId: number): Promise<void | VehicleCameraPairingResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'devicePairResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceAssociationApi = DeviceAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceAssociationApi.apiV1DeviceAssociationDevicePairIdGet(params);
            },
            params: {
                id: pairId
            },
            onOk: (data: VehicleCameraPairingResponse) => {
                if(data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async createDevicePair(request: CreatePairingRequest): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'createPairResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceAssociationApi = DeviceAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceAssociationApi.apiV1DeviceAssociationDevicePairPost(params);
            },
            params: {
                body: request
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }

    public async unpairDevicePair(pairId: number): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'unpairResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const deviceAssociationApi = DeviceAssociationApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return deviceAssociationApi.apiV1DeviceAssociationDevicePairIdDelete(params);
            },
            params: {
                id: pairId
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });
    }
}

export const DevicePairService = new DevicePairFreezerService();
export type IDevicePairServiceInjectedProps = ReturnType<
    DevicePairFreezerService['getPropsForInjection']
>;