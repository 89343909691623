import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';

import { VideoEventApiFactory, VideoEventResponse } from '$Generated/api';

const InjectedPropName = 'videoEvent';

interface IVideoEventState {
    hasError: boolean;
    requestVideoTestResult: IAjaxState<void>;
    pollVideoTestResult: IAjaxState<VideoEventResponse>;
}

class VideoEventFreezerService extends FreezerService<IVideoEventState, typeof InjectedPropName> {
    constructor() {
        super(
            {
                hasError: false,
                requestVideoTestResult: managedAjaxUtil.createInitialState(),
                pollVideoTestResult: managedAjaxUtil.createInitialState(),
            },
            InjectedPropName,
        );
    }

    public async requestVideoTest(integrationPartnerVehicleId: string): Promise<void> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'requestVideoTestResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const videoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return videoEventApi.apiV1VideoEventVideoTestPost(params);
            },
            params: {
                body: integrationPartnerVehicleId,
            },
            onOk: () => {
                this.freezer.get().set({ hasError: false });
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }

    public async pollVideoTest(integrationPartnerVehicleId: string, cameraDeviceId: number): Promise<void | VideoEventResponse> {
        return managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'pollVideoTestResult',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const videoEventApi = VideoEventApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return videoEventApi.apiV1VideoEventVideoTestGet(params);
            },
            params: {
                integrationPartnerVehicleId: integrationPartnerVehicleId,
                cameraDeviceId: cameraDeviceId,
            },
            onOk: (data: VideoEventResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            },
        });
    }
}

export const VideoEventService = new VideoEventFreezerService();
export type IVideoEventServiceInjectedProps = ReturnType<VideoEventFreezerService['getPropsForInjection']>;
