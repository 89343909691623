import Freezer = require('freezer-js');
import { FreezerService } from './externals/FreezerService';
import { BarcodeService } from './externals/BarcodeService';
import { bind } from 'bind-decorator';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as React from 'react';
import { Route, Redirect } from 'react-router';
import { BrowserRouter as Router, Link, Switch, withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import * as managedAjaxUtil from './modules/managedAjaxUtil';
import { IAjaxState } from './modules/managedAjaxUtil';
import * as s from 'underscore.string';

export { React, Router, Route, Redirect, Switch, withRouter, RouteComponentProps, Link, NavLink, bind, _, FreezerService, BarcodeService, moment, IAjaxState, managedAjaxUtil, s };
