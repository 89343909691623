import { React } from '../../Imports';

import { Card, CardContent, Grid } from 'MaterialUIComponents';
import { VPCardGridField } from './VPCardGridField';

import { VehicleResponse } from '$Generated/api';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';

interface IVehicleBaseProps {
    vehicle: VehicleResponse;
    className?: string;
    onClick?: () => void;
}

type IVehicleProps = IVehicleBaseProps
    & IConfigServiceInjectedProps

interface IVehicleState { }

class _VPVehicleCard extends React.PureComponent<IVehicleProps, IVehicleState> {
    render(): JSX.Element {
        const { vehicle, ...other } = this.props;
        const isGeotab = this.props.config.getIsIntegrationPlatformGeotab();
        return (
            <Card {...other} >
                <CardContent>
                    <Grid container>
                        <VPCardGridField
                            label={isGeotab ? "GO Device" : "Vehicle"}
                            value={vehicle.name}
                            xs={12}
                        />

                         
                        { isGeotab && 
                            <VPCardGridField
                                label="Serial #"
                                value={vehicle.telematicDevice?.serialNumber}
                                xs={12}
                            />
                        }
                        { isGeotab &&
                             <VPCardGridField
                                label="Type"
                                value={vehicle.telematicDevice?.type ?? '-'}
                                xs={12}
                                sm={6}
                            />
                        }
                        { isGeotab &&
                             <VPCardGridField
                                label="Device ID"
                                value={vehicle.integrationPartnerVehicleId}
                                xs={12}
                                sm={6}
                            />
                        }
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export const VPVehicleCard = ConfigService.inject(_VPVehicleCard);
