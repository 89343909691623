import { bind, React, moment } from 'Imports';
import ReactPlayer from 'react-player';
import { cx } from '@videoplatform/css-helpers';

import { Button, Card, CardContent, CardHeader, Grid, Table, TableBody, TableCell, TableRow, Typography } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faTrash, faExclamationCircle, faClock } from '@fortawesome/pro-solid-svg-icons';

import * as DateFormatter from '$Components/Shared/DateFormatter';
import { Page } from '$Components/Shared/Page';
import { PageHeader } from '$Components/Shared/PageHeader';
import { PageContent } from '$Components/Shared/PageContent';
import { PageFooter } from '$Components/Shared/PageFooter';
import { UnpairConfirmDialog } from '$Components/Shared/UnpairConfirmDialog';

import { VehicleCameraPairingResponse, VideoEventResponse } from '$Generated/api';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IDevicePairServiceInjectedProps, DevicePairService } from '$State/DevicePairFreezerService';
import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';

interface ISignedVideoAsset {
    signedURL: string;
}

interface IDevicePairDetailsBaseProps {
    devicePairId: number;
    onSelectDevicePair: (pairId?: number) => void;
    onUnpairDevicePair: (pairId?: number) => void;
}

type IDevicePairDetailsProps = IDevicePairDetailsBaseProps &
    IConfigServiceInjectedProps &
    IDevicePairServiceInjectedProps &
    IVideoEventServiceInjectedProps;

interface IDevicePairDetailsState {
    devicePair?: VehicleCameraPairingResponse;
    isPolling: boolean;
    canRequestNewVideoAt?: string;
    testVideo?: VideoEventResponse;
    testVideoUrls?: string[];
    testVideoHasError: boolean;
    showUnpairDialog: boolean;
}

const styles = require('./DevicePairDetails.scss') as {
    noPair: string;
    pairGridItem: string;
    tableRow: string;
    tableFieldName: string;
    tableFieldValue: string;
    videoCardContent: string;
    testVideoMessage: string;
    pollWaitMessage: string;
    testVideoPlayer: string;
    buttonLabel: string;
    buttonOutlined: string;
    buttonIcon: string;
    icon: string;
};

const pollInterval: number = 30;

class _DevicePairDetails extends React.Component<IDevicePairDetailsProps, IDevicePairDetailsState> {
    state: IDevicePairDetailsState = {
        devicePair: undefined,
        isPolling: false,
        canRequestNewVideoAt: undefined,
        testVideo: undefined,
        testVideoUrls: undefined,
        testVideoHasError: false,
        showUnpairDialog: false,
    };

    players: { [key: string]: ReactPlayer | null } = {};

    private _testVideoInterval?: number = undefined;

    async componentDidMount(): Promise<void> {
        const { devicePairId, devicePair: devicePairService } = this.props;

        await devicePairService.getDevicePair(devicePairId);
        const devicePairState = devicePairService.getState();
        const devicePair = devicePairState.devicePairResult.data;

        if (devicePair) {
            this.setState({ devicePair: devicePair });

            await this.pollForTestVideos();
        }
    }

    componentWillUnmount(): void {
        this.cancelTestVideoPoll();
    }

    @bind
    backToPairedDevices(): void {
        this.props.onSelectDevicePair(undefined);
    }

    @bind
    toggleUnpairDialog(): void {
        this.setState((state) => {
            return { showUnpairDialog: !state.showUnpairDialog };
        });
    }

    @bind
    async requestTestVideo(): Promise<void> {
        const { devicePair } = this.state;

        await this.props.videoEvent.requestVideoTest(devicePair?.vehicle?.integrationPartnerVehicleId!);
        await this.pollForTestVideos();
    }

    render(): JSX.Element {
        const { showUnpairDialog, isPolling, canRequestNewVideoAt, devicePair, testVideo, testVideoUrls, testVideoHasError } = this.state;
        const cameraStatusClass = cx([styles.tableFieldValue, devicePair?.camera?.deviceStatus == 'Active' ? 'positive' : 'negative']);

        const isGeotab = this.props.config.getIsIntegrationPlatformGeotab();

        return (
            <Page>
                <PageHeader pageTitle="Paired Device Details" />

                <PageContent>
                    {!devicePair ? (
                        <Typography component="h2" variant="h5" className={styles.noPair}>
                            Paired device not found.
                        </Typography>
                    ) : (
                        <Grid container>
                            <Grid item md={12} lg={6} className={styles.pairGridItem}>
                                <Table>
                                    <TableBody>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell className={styles.tableFieldName}>Date Paired</TableCell>
                                            <TableCell className={styles.tableFieldValue}>
                                                {DateFormatter.dateAndTimezone(moment(devicePair.startDate))}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow className={styles.tableRow}>
                                            <TableCell className={styles.tableFieldName}>{isGeotab ? 'GO Device' : 'Vehicle'}</TableCell>
                                            <TableCell className={styles.tableFieldValue}>{devicePair.vehicle?.name ?? '-'}</TableCell>
                                        </TableRow>

                                        {isGeotab && (
                                            <TableRow className={styles.tableRow}>
                                                <TableCell className={styles.tableFieldName}>GO Device Serial #</TableCell>
                                                <TableCell className={styles.tableFieldValue}>
                                                    {devicePair.vehicle?.telematicDevice?.serialNumber ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {isGeotab && (
                                            <TableRow className={styles.tableRow}>
                                                <TableCell className={styles.tableFieldName}>GO Device Provider</TableCell>
                                                <TableCell className={styles.tableFieldValue}>
                                                    {devicePair.vehicle?.telematicDevice?.providerName ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {isGeotab && (
                                            <TableRow className={styles.tableRow}>
                                                <TableCell className={styles.tableFieldName}>GO Device Type</TableCell>
                                                <TableCell className={styles.tableFieldValue}>
                                                    {devicePair.vehicle?.telematicDevice?.type ?? '-'}
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        <TableRow className={styles.tableRow}>
                                            <TableCell className={styles.tableFieldName}>Camera Serial #</TableCell>
                                            <TableCell className={styles.tableFieldValue}>
                                                {devicePair.camera?.serialNumber ?? '-'}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow className={styles.tableRow}>
                                            <TableCell className={styles.tableFieldName}>Camera Type</TableCell>
                                            <TableCell className={styles.tableFieldValue}>{devicePair.camera?.deviceType ?? '-'}</TableCell>
                                        </TableRow>

                                        <TableRow className={styles.tableRow}>
                                            <TableCell className={styles.tableFieldName}>Camera ICCID</TableCell>
                                            <TableCell className={styles.tableFieldValue}>{devicePair.camera?.iccid ?? '-'}</TableCell>
                                        </TableRow>

                                        <TableRow className={styles.tableRow}>
                                            <TableCell className={styles.tableFieldName}>Camera Status</TableCell>
                                            <TableCell className={cameraStatusClass}>{devicePair.camera?.deviceStatus ?? '-'}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>

                            <Grid item md={12} lg={6} className={styles.pairGridItem}>
                                <Card>
                                    <CardHeader
                                        title="Test video"
                                        avatar={
                                            !isPolling ? (
                                                <FontAwesomeIcon icon={faClock} className={styles.icon} />
                                            ) : !testVideoHasError ? (
                                                <FontAwesomeIcon icon={faExclamationCircle} className={styles.icon} />
                                            ) : null
                                        }
                                    />
                                    <CardContent className={styles.videoCardContent}>
                                        {isPolling && !testVideo ? (
                                            <Typography className={styles.testVideoMessage}>Waiting for test video request...</Typography>
                                        ) : testVideo ? (
                                            <React.Fragment>
                                                {isPolling ? (
                                                    <React.Fragment>
                                                        <Typography className={styles.testVideoMessage}>
                                                            Test video has been requested.
                                                        </Typography>

                                                        <Typography className={styles.testVideoMessage}>
                                                            Date requested: {DateFormatter.dateAndTimezone(moment(testVideo.requestedOn))}
                                                        </Typography>

                                                        <Typography className={styles.testVideoMessage}>Status: Pending</Typography>
                                                    </React.Fragment>
                                                ) : testVideoHasError ? (
                                                    <Typography className={styles.testVideoMessage}>
                                                        Test video processing failed.
                                                    </Typography>
                                                ) : testVideoUrls ? (
                                                    testVideoUrls.map((x, index) => (
                                                        <ReactPlayer
                                                            key={`player_${index}`}
                                                            className={styles.testVideoPlayer}
                                                            url={x}
                                                            ref={(player) => (this.players[index] = player)}
                                                            controls={true}
                                                            width="100%"
                                                            muted={true}
                                                        />
                                                    ))
                                                ) : null}
                                            </React.Fragment>
                                        ) : (
                                            <Typography className={styles.testVideoMessage}>No recent test videos found</Typography>
                                        )}

                                        <Button
                                            variant="outlined"
                                            classes={{ outlined: styles.buttonOutlined, root: styles.buttonLabel }}
                                            onClick={this.requestTestVideo}
                                            disabled={!!canRequestNewVideoAt}
                                        >
                                            Request New Video
                                        </Button>
                                        {canRequestNewVideoAt && (
                                            <Typography className={styles.pollWaitMessage}>
                                                Can request a new test video after:
                                                <br />
                                                {canRequestNewVideoAt}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                </PageContent>

                <PageFooter
                    right={
                        <React.Fragment>
                            <Button
                                variant="outlined"
                                classes={{ outlined: styles.buttonOutlined, root: styles.buttonLabel }}
                                style={{ marginRight: '1rem' }}
                                onClick={this.backToPairedDevices}
                                startIcon={<FontAwesomeIcon icon={faAngleLeft} className={[styles.icon, styles.buttonIcon].join(' ')} />}
                            >
                                Back
                            </Button>

                            <Button
                                variant="outlined"
                                classes={{ outlined: styles.buttonOutlined, root: styles.buttonLabel }}
                                onClick={this.toggleUnpairDialog}
                                startIcon={<FontAwesomeIcon icon={faTrash} className={[styles.icon, styles.buttonIcon].join(' ')} />}
                            >
                                Unpair
                            </Button>
                        </React.Fragment>
                    }
                />

                {showUnpairDialog && devicePair && (
                    <UnpairConfirmDialog
                        onUnpair={() => {
                            this.props.onUnpairDevicePair(devicePair.id);
                            this.toggleUnpairDialog();
                            this.backToPairedDevices();
                        }}
                        onCancel={this.toggleUnpairDialog}
                    />
                )}
            </Page>
        );
    }

    @bind
    private async pollForTestVideos(): Promise<void> {
        // stop any existing polling before starting another video test
        this.cancelTestVideoPoll();

        const shouldPoll = await this.checkTestVideoResults();
        if (shouldPoll) {
            this._testVideoInterval = (setInterval(async () => {
                const continuePoll = await this.checkTestVideoResults();
                if (!continuePoll) {
                    this.cancelTestVideoPoll();
                }
            }, pollInterval * 1000) as unknown) as number; // double-cast validates setInterval return as number, not a Node timer
        }
    }

    @bind
    private cancelTestVideoPoll(): void {
        if (this._testVideoInterval) {
            clearInterval(this._testVideoInterval);
        }

        this.setState({ isPolling: false });
    }

    @bind
    private async checkTestVideoResults(): Promise<boolean> {
        const { videoEvent: videoEventService } = this.props;

        const { devicePair } = this.state;
        if (!devicePair) {
            return false;
        }

        await this.props.videoEvent.pollVideoTest(devicePair.vehicle?.integrationPartnerVehicleId!, devicePair.camera?.id!);

        let testVideoUrls: string[] | undefined = undefined;
        const videoEventState = videoEventService.getState();
        const testVideoEvent = videoEventState.pollVideoTestResult.data || undefined;

        let isPolling = false;
        let hasError = false;

        switch (testVideoEvent?.workflowStep) {
            case 'Success':
                testVideoUrls = testVideoEvent?.videoAsset
                    ? testVideoEvent.videoAsset
                          .filter((x) => x.type === 'Transcode')
                          .map((x) => {
                              const storageDetails = JSON.parse(x.storageDetailsJson || '{}') as ISignedVideoAsset;
                              // HACK: local testing; return a web-accessible video here to temporarily overwrite returned video assets
                              // return "";
                              return storageDetails.signedURL;
                          })
                    : [];
                break;
            case 'Failed':
                // don't continue polling
                hasError = true;
                break;
            default:
                // no video test or still in process
                isPolling = !!testVideoEvent;
                break;
        }

        // require waiting at least 3 minutes for most recent test video to process before allowing a new request
        const minimumWaitDate = isPolling && testVideoEvent ? moment(testVideoEvent.requestedOn).add(3, 'minutes') : undefined;
        const canRequestNewVideoAt =
            minimumWaitDate && moment().isBefore(minimumWaitDate) ? DateFormatter.dateAndTimezone(minimumWaitDate) : undefined;

        this.setState({
            isPolling: isPolling,
            canRequestNewVideoAt: canRequestNewVideoAt,
            testVideo: testVideoEvent,
            testVideoUrls: testVideoUrls,
            testVideoHasError: hasError,
        });

        return isPolling;
    }
}

export const DevicePairDetails = ConfigService.inject(DevicePairService.inject(VideoEventService.inject(_DevicePairDetails)));
