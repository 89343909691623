// #region Imports
import { bind, React } from './Imports';
import { withStyles, WithStyles, createTheme, ThemeProvider, StyledEngineProvider } from 'MaterialUIComponents';
import { muiStyles, muiTheme } from './MaterialUIStyles';

// framework
import { getLogger } from '@videoplatform/logging';
import { ISinglePageApplicationProps } from '@videoplatform/react-spa';

// application
import { PageRouter } from '$Pages/PageRouter';

import { IdentityService, IIdentityServiceInjectedProps } from './state/IdentityFreezerService';
import { DevicePairService, IDevicePairServiceInjectedProps } from '$State/DevicePairFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { init as initApm } from '@elastic/apm-rum';

// #endregion Imports

// Include the global styles
require('./css/global.scss');

const logger = getLogger('Application');
interface IApplicationState {
    imagesBucket: string;
    imagesBucketRegion: string;
    isLoggedIn: boolean;
    isSettled: boolean;
}

type IApplicationProps = ISinglePageApplicationProps &
    IIdentityServiceInjectedProps &
    IDevicePairServiceInjectedProps &
    IConfigServiceInjectedProps &
    WithStyles<keyof ReturnType<typeof muiStyles>>;

export class _Application extends React.Component<IApplicationProps, IApplicationState> {
    state = {
        imagesBucket: '',
        imagesBucketRegion: '',
        isLoggedIn: false,
        isSettled: false,
    };

    @bind
    async onLogin(): Promise<void> {
        await this.checkUserLogin();
    }

    @bind
    onLogout(): void {
        this.setState({ isLoggedIn: false });
    }

    async componentDidMount(): Promise<void> {
        await this.props.config.getClientConfig();
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        this.setState({ imagesBucket: currentImagesBucket ? currentImagesBucket : '' });

        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;
        this.setState({ imagesBucketRegion: currentRegion ? currentRegion : '' });

        await this.checkUserLogin();
        const apm = initApm({
            // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
            serviceName: ConfigService.getApmServiceName(), //'ui_vprotect',
            // Set custom APM Server URL
            serverUrl: ConfigService.getApmServiceUrl(),
            // Set service environment (required for sourcemap feature)
            environment: ConfigService.getDeployEnvironment(),
        });
        apm.addLabels({ IntegrationPlatform: ConfigService.getIntegrationPlatform() });
    }

    render(): JSX.Element {
        logger.info('Rendering application');
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <div className={this.props.classes.root}>
                        <main className={this.props.classes.content}>
                            <PageRouter
                                {...this.props}
                                isLoggedIn={this.state.isLoggedIn}
                                isSettled={this.state.isSettled}
                                onLogin={this.onLogin}
                                onLogout={this.onLogout}
                            />
                        </main>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    private async checkUserLogin() {
        let isLoggedIn: boolean = false;
        let isFleetSet: boolean = false;

        try {
            // always use current user endpoint - even on fresh login - to also get selected fleet
            await this.props.identity.getCurrentUser();
            const currentUser = this.props.identity.getState().currentUserResult.data || undefined;

            isLoggedIn = !!currentUser;
            isFleetSet = !!currentUser?.currentFleetId;
        } catch (e) {
            console.log(e);
            // exception in getting current user will auto-logout
        }

        // initial load of data for the logged-in user if they have a fleet set
        if (isLoggedIn && isFleetSet) {
            await this.props.devicePair.getPairedDevices();
            await this.props.devicePair.getUnpairedCameras();
            await this.props.devicePair.getUnpairedVehicles();
        }

        // only considered completely logged in if user has also set a fleet
        this.setState({
            isLoggedIn: isLoggedIn && isFleetSet,
            isSettled: true, // prevent login flash while still determining status
        });
    }
}

const Application = withStyles(muiStyles)(IdentityService.inject(ConfigService.inject(DevicePairService.inject(_Application))));

export { Application, IApplicationProps };
