import { React, moment } from '../../Imports';

import { Card, CardContent, Grid } from 'MaterialUIComponents';
import { VPCardGridField } from './VPCardGridField';

import { dateAndTimezone } from '$Components/Shared/DateFormatter';
import { CameraDeviceResponse } from '$Generated/api';

interface ICameraProps {
    camera: CameraDeviceResponse;
    className?: string;
    onClick?: () => void;
}

interface ICameraState { }

class _VPCameraCard extends React.PureComponent<ICameraProps, ICameraState> {
    render(): JSX.Element {
        const { camera, ...other } = this.props;
        const statusClassName = camera.deviceStatus == 'Active' ? 'positive' : 'negative';

        return (
            <Card {...other} >
                <CardContent>
                    <Grid container>
                        <VPCardGridField
                            label="Serial #"
                            value={camera.serialNumber}
                            xs={6}
                        />

                        <VPCardGridField
                            label="Status"
                            value={camera.deviceStatus}
                            xs={6}
                            className={statusClassName}
                        />

                        <VPCardGridField
                            label="Type"
                            value={camera.deviceType}
                            xs={6}
                        />

                        <VPCardGridField
                            label="ICCID"
                            value={camera.iccid || '-'}
                            xs={12}
                            md={6}
                        />
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export const VPCameraCard = _VPCameraCard;