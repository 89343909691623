import { React } from '../../Imports';

const styles = require("./Page.scss") as {
    main: string;
};

interface IPageProps {}

export class Page extends React.PureComponent<React.PropsWithChildren<IPageProps>, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.main}>
                {this.props.children}
            </div>
        );
    }
}