import { bind, React, _, withRouter, RouteComponentProps, NavLink } from 'Imports';

import {
    Button,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    CardMedia,
    TextValidator,
    ValidatorForm,
    KnownValidatorComponent,
    CircularProgress,
} from 'MaterialUIComponents';

import { IdentityService, IIdentityServiceInjectedProps } from '$State/IdentityFreezerService';
import { LoginUserRequest } from '$Generated/api';

import { getErrorMessages } from 'utilities/formUtilities';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

interface IResetPasswordBaseProps {
    endpoint: 'verify' | 'resetPassword';
}

type IResetPasswordProps = IResetPasswordBaseProps & RouteComponentProps & IIdentityServiceInjectedProps & IConfigServiceInjectedProps;

interface IResetPasswordState {
    user: LoginUserRequest;
    action: string;
    confirmPassword: string;
    errorMessages: string[];
    tokenExpired: boolean;
    tokenVerified: boolean;
    pwdDto: passwordDto;
}

const styles = require('./ResetPassword.scss') as {
    main: string;
    content: string;
    errorMessages: string;
    actions: string;
    logo: string;
    submitButton: string;
    expiredContainer: string;
    actionText: string;
    loginButton: string;
};

interface passwordDto {
    password: string;
    confirmPassword: string;
    obfuscatedPassword: string;
    obfuscatedConfirmPassword: string;
}

class _ResetPassword extends React.Component<IResetPasswordProps, IResetPasswordState> {
    state: IResetPasswordState = {
        user: {
            username: '',
            password: '',
            token: '',
        },
        action: '',
        confirmPassword: '',
        errorMessages: [],
        tokenExpired: false,
        tokenVerified: false,
        pwdDto: {
            password: '',
            confirmPassword: '',
            obfuscatedPassword: '',
            obfuscatedConfirmPassword: '',
        },
    };

    componentDidMount(): void {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        const uid = searchParams.get('uid');
        const token = searchParams.get('token');

        if (uid !== null && token !== null) {
            this.setState({
                user: {
                    username: uid,
                    token: token,
                    password: '',
                },
                action: this.props.endpoint === 'verify' ? 'Verify Email' : 'Reset Password',
            });

            if (this.props.endpoint === 'verify') {
                this.verifyToken(uid, token);
            } else {
                this.setState({
                    tokenExpired: false,
                    tokenVerified: true,
                });
            }
        } else {
            this.returnToLanding();
        }

        ValidatorForm.addValidationRule('isPasswordMatch', (): boolean => {
            return this.state.user['password'] === this.state.pwdDto['confirmPassword'];
        });
        ValidatorForm.addValidationRule('containsLowerAlpha', (): boolean => {
            // Create Regex
            const regex = new RegExp('[a-z]', 'g');

            const pd: string = this.state.pwdDto['password'] ?? '';
            const regexResult = regex.exec(pd);

            if (regexResult !== null) {
                return true;
            } else {
                return false;
            }
        });
        ValidatorForm.addValidationRule('containsUpperAlpha', (): boolean => {
            // Create Regex
            const regex = new RegExp('[A-Z]', 'g');

            const pd: string = this.state.pwdDto['password'] ?? '';
            const regexResult = regex.exec(pd);

            if (regexResult !== null) {
                return true;
            } else {
                return false;
            }
        });
        ValidatorForm.addValidationRule('containsNumeric', (): boolean => {
            // Create Regex
            const regex = new RegExp('[0-9]', 'g');

            const pd: string = this.state.pwdDto['password'] ?? '';
            const regexResult = regex.exec(pd);

            if (regexResult !== null) {
                return true;
            } else {
                return false;
            }
        });
        ValidatorForm.addValidationRule('containsSymbol', (): boolean => {
            // Create Regex
            const regex = new RegExp('[^a-zA-Z0-9]', 'g');

            const pd: string = this.state.pwdDto['password'] ?? '';
            const regexResult = regex.exec(pd);

            if (regexResult !== null) {
                return true;
            } else {
                return false;
            }
        });
    }

    componentWillUnmount(): void {
        ValidatorForm.removeValidationRule('matchPassword');
    }

    @bind
    async verifyToken(uid: string, token: string) {
        const user = {
            username: uid,
            token: token,
        };
        try {
            await this.props.identity.verifyToken(user);
            this.setState({
                tokenExpired: false,
                tokenVerified: true,
            });
        } catch {
            this.setState({
                tokenExpired: true,
                tokenVerified: true,
                action: 'Login',
            });
        }
    }

    @bind
    async resetPassword() {
        const { user } = this.state;
        const { endpoint } = this.props;
        this.setState({ errorMessages: [] });

        try {
            // both email verification and standard password reset have same
            switch (endpoint) {
                case 'resetPassword':
                    await this.props.identity.resetPassword(user);
                    break;
                case 'verify':
                    await this.props.identity.verifyEmail(user);
                    break;
            }

            this.returnToLanding();
        } catch {
            this.setState({
                errorMessages: ['There was an error trying to reset your password'],
            });
        }
    }

    @bind
    returnToLanding(): void {
        this.props.history.push('/');
    }

    obfuscateText(plainText: string): string {
        if (plainText.length != 0) {
            return '*'.repeat(plainText.length);
        } else {
            return '';
        }
    }

    handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
        // Clone needed state data objects
        const currentPassword: string = this.state.pwdDto.password ?? '';

        // Extract input information
        const inputPassword: string = event.target.value;

        const tmpPasswordDto: passwordDto = this.calculateObfuscation('password', currentPassword, inputPassword);

        const newPwdDto: passwordDto = _.clone(this.state.pwdDto);

        newPwdDto['password'] = tmpPasswordDto['password'];
        newPwdDto['obfuscatedPassword'] = tmpPasswordDto['obfuscatedPassword'];

        const newUser: LoginUserRequest = _.clone(this.state.user);

        newUser['password'] = tmpPasswordDto['password'];

        this.setState({ pwdDto: newPwdDto, user: newUser });
    }

    handleConfirmPasswordChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const currentConfirmationPassword: string = this.state.pwdDto.confirmPassword ?? '';

        const inputConfirmationPassword: string = event.target.value;

        const tmpPasswordDto: passwordDto = this.calculateObfuscation(
            'confirmation',
            currentConfirmationPassword,
            inputConfirmationPassword,
        );

        const newPwdDto: passwordDto = _.clone(this.state.pwdDto);

        newPwdDto['confirmPassword'] = tmpPasswordDto['confirmPassword'];
        newPwdDto['obfuscatedConfirmPassword'] = tmpPasswordDto['obfuscatedConfirmPassword'];

        this.setState({ pwdDto: newPwdDto, confirmPassword: tmpPasswordDto['confirmPassword'] });
    }

    calculateObfuscation(field: 'password' | 'confirmation', currentValue?: string, inputValue?: string): passwordDto {
        const returnPwdDto: passwordDto = {
            password: '',
            obfuscatedPassword: '',
            confirmPassword: '',
            obfuscatedConfirmPassword: '',
        };
        const currentLength: number = currentValue?.length ?? 0;
        const inputLength: number = inputValue?.length ?? 0;

        if (inputLength === 0) {
            if (field === 'password') {
                returnPwdDto['password'] = '';
                returnPwdDto['obfuscatedPassword'] = '';
            } else {
                returnPwdDto['confirmPassword'] = '';
                returnPwdDto['obfuscatedConfirmPassword'] = '';
            }
        } else if (inputLength === currentLength - 1) {
            if (field === 'password') {
                returnPwdDto['password'] = currentValue?.substring(0, currentLength - 1) ?? '';
                returnPwdDto['obfuscatedPassword'] = this.obfuscateText(currentValue?.substring(0, currentLength - 1) ?? '');
            } else {
                returnPwdDto['confirmPassword'] = currentValue?.substring(0, currentLength - 1) ?? '';
                returnPwdDto['obfuscatedConfirmPassword'] = this.obfuscateText(currentValue?.substring(0, currentLength - 1) ?? '');
            }
        } else if (inputLength > currentLength + 1 || inputLength < currentLength - 1) {
            if (field === 'password') {
                returnPwdDto['password'] = inputValue ?? '';
                returnPwdDto['obfuscatedPassword'] = this.obfuscateText(inputValue ?? '');
            } else {
                returnPwdDto['confirmPassword'] = inputValue ?? '';
                returnPwdDto['obfuscatedConfirmPassword'] = this.obfuscateText(inputValue ?? '');
            }
        } else {
            if (field === 'password') {
                returnPwdDto['password'] = currentValue + (inputValue?.slice(-1) ?? '');
                returnPwdDto['obfuscatedPassword'] = this.obfuscateText(currentValue + (inputValue?.slice(-1) ?? ''));
            } else {
                returnPwdDto['confirmPassword'] = currentValue + (inputValue?.slice(-1) ?? '');
                returnPwdDto['obfuscatedConfirmPassword'] = this.obfuscateText(currentValue + (inputValue?.slice(-1) ?? ''));
            }
        }

        return returnPwdDto;
    }

    expiredMessage() {
        return (
            <>
                <div className={styles.expiredContainer}>
                    <h1>Your verification link has expired.</h1>
                    <span>Please contact your support representative.</span>
                </div>
                <Button variant="contained" classes={{ root: styles.loginButton }} onClick={this.returnToLanding}>
                    Log In
                </Button>

                <NavLink className={styles.actionText} exact={true} to="/ForgotPassword">
                    Forgot Password?
                </NavLink>
            </>
        );
    }

    render(): JSX.Element {
        const { errorMessages, user, action, confirmPassword } = this.state;
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        return (
            <div className={styles.main}>
                <Card className={styles.content}>
                    <CardHeader title={`Installer ${action}`} titleTypographyProps={{ variant: 'h2' }} />
                    {!this.state.tokenVerified ? (
                        <CircularProgress></CircularProgress>
                    ) : this.state.tokenExpired ? (
                        this.expiredMessage()
                    ) : (
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.resetPassword}
                            onError={(errors: KnownValidatorComponent[]) => {
                                this.setState({ errorMessages: getErrorMessages(errors) });
                            }}
                            instantValidate={true}
                        >
                            <CardContent>
                                {errorMessages.length ? (
                                    <ul className={styles.errorMessages}>
                                        {errorMessages.map((x, index) => (
                                            <li key={index}>{x}</li>
                                        ))}
                                    </ul>
                                ) : null}

                                <TextValidator
                                    name="password"
                                    label="Password"
                                    type="password"
                                    margin="normal"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={(e: any) => this.handlePasswordChange(e)}
                                    value={this.state.pwdDto.obfuscatedPassword}
                                    validators={[
                                        'required',
                                        'minStringLength:8',
                                        'containsLowerAlpha',
                                        'containsUpperAlpha',
                                        'containsNumeric',
                                        'containsSymbol',
                                    ]}
                                    errorMessages={[
                                        'Password is required',
                                        'Password must be at least 8 characters',
                                        'Password must contain at least one lower-case character',
                                        'Password must contain at least one upper-case character',
                                        'Password must contain at least one numeric digit',
                                        'Password must contain at least one special character',
                                    ]}
                                    required={true}
                                />

                                <TextValidator
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    margin="normal"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={(e: any) => this.handleConfirmPasswordChange(e)}
                                    value={this.state.pwdDto.obfuscatedConfirmPassword}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={['Confirm Password is required', 'Confirm Password must match Password']}
                                    required={true}
                                />
                            </CardContent>

                            <CardActions className={styles.actions}>
                                <Button color="primary" variant="contained" type="submit" className={styles.submitButton}>
                                    {action}
                                </Button>
                            </CardActions>
                        </ValidatorForm>
                    )}

                    <CardMedia
                        alt="VideoProtects logo"
                        className={styles.logo}
                        component="img"
                        image={GetImageUrl(currentImagesBucket ? currentImagesBucket : '', currentRegion ? currentRegion : '', 'logo.png')}
                    />
                </Card>
            </div>
        );
    }
}

export const ResetPassword = withRouter(IdentityService.inject(ConfigService.inject(_ResetPassword)));
