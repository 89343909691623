import { bind, React, Redirect, Route, Router, Switch, s } from 'Imports'

import { IApplicationProps } from 'Application'
import { ApiContext, IApplicationOptions } from 'utilities/api'

import { CreateDevicePair } from '$Pages/CreateDevicePair/CreateDevicePair';
import { DevicePairList } from '$Pages/DevicePairList/DevicePairList';
import { DevicePairDetails } from '$Pages/DevicePairDetails/DevicePairDetails';
import { ForgotPassword } from '$Pages/ForgotPassword/ForgotPassword'
import { Login } from '$Pages/Login/Login'
import { ResetPassword } from '$Pages/ResetPassword/ResetPassword'

interface IPageRouterBaseProps {
    onLogin: () => Promise<void>;
    onLogout: () => void;
    isLoggedIn: boolean;
    isSettled: boolean;
};

type IPageRouterProps = IPageRouterBaseProps & IApplicationProps;

interface IPageRouterState {
    apiOptions: IApplicationOptions;
    selectedDevicePair?: number;
}

export class PageRouter extends React.Component<IPageRouterProps, IPageRouterState> {
    state = {
        apiOptions: {
            // The current api generator can't handle "/" as a base url, so we replace that with a
            // fully qualified base URL with trailing slashes removed
            applicationRoot: this.props.baseUrl,
            apiBaseUrl: s.rtrim(`${this.props.apiBaseUrl}`, '/'),
            wrappedFetch: fetch,
        },
        selectedDevicePair: undefined,
    };

    @bind
    onSelectDevicePair(pairId?: number): void {
        this.setState({ selectedDevicePair: pairId });
    }

    @bind
    async onUnpairDevicePair(pairId?: number): Promise<void> {
        if(!pairId) { return; }

        try
        {
            await this.props.devicePair.unpairDevicePair(pairId);

            await this.props.devicePair.getPairedDevices();
            await this.props.devicePair.getUnpairedCameras();
            await this.props.devicePair.getUnpairedVehicles();
        }
        catch (ex) {
            // TODO: show error message back to installer
        }
    }


    render(): JSX.Element {
        const { apiOptions, selectedDevicePair } = this.state; 

        return (
        <>
        {
            !this.props.isSettled ? (<div>Loading...</div>) 
            : (
                <Router>
                    { !this.props.isLoggedIn ? (
                        <Switch>
                            <Route
                                path="/login"
                                exact={true}
                                render={(): React.ReactNode => {
                                    this.props.setPageTitle('Login');
                                    return (
                                        <Login onLogin={this.props.onLogin} />
                                    );
                                }}
                            />   
                            <Route
                                path="/resetPassword"
                                exact={true}
                                render={(): React.ReactNode => {
                                    this.props.setPageTitle('Reset Password');
                                    return (
                                        <ResetPassword endpoint="resetPassword" />
                                    );
                                }}
                            />
                            <Route
                                path="/verify"
                                exact={true}
                                render={(): React.ReactNode => {
                                    this.props.setPageTitle('Verify Email');
                                    return (
                                        <ResetPassword endpoint="verify" />
                                    );
                                }}
                            />
                            <Route
                                path="/forgotPassword"
                                exact={true}
                                render={(): React.ReactNode => {
                                    this.props.setPageTitle('Forgot Password');
                                    return (
                                        <ForgotPassword />
                                    );
                                }}
                            /> 
                            <Redirect to="/login" />      
                        </Switch>
                    ) : (
                        <ApiContext.Provider value={apiOptions}>
                            <Switch>
                                <Route
                                    path="/logout"
                                    exact={true}
                                    render={(): React.ReactNode => {
                                        this.props.onLogout();
                                        this.setState({selectedDevicePair: undefined})
                                        return (
                                            <Redirect to="/login" />
                                        );
                                    }}
                                />
                                <Route
                                    path="/pairlist"
                                    exact={true}
                                    render={(): React.ReactNode => {
                                        this.props.setPageTitle('Device Pairs');

                                        return !selectedDevicePair ? (
                                            <DevicePairList 
                                            onSelectDevicePair={this.onSelectDevicePair}
                                            onUnpairDevicePair={this.onUnpairDevicePair}
                                            />                  
                                        ) : (
                                            <DevicePairDetails
                                                devicePairId={selectedDevicePair!}
                                                onSelectDevicePair={this.onSelectDevicePair}
                                                onUnpairDevicePair={this.onUnpairDevicePair}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    path="/createpair"
                                    exact={true}
                                    render={(): React.ReactNode => {
                                        this.props.setPageTitle('Create Pair');
                                        return ( <CreateDevicePair /> );
                                    }}
                                />
                                <Redirect to="/pairlist" />
                            </Switch>
                        </ApiContext.Provider> 
                    )
                    }
                </Router>
            )
        }
        </>
        )
    }
}
