export function stringItemFilter<T, U extends keyof T>(source: T[], fields: U[], search?: string): T[] {
    if(!search) {
        return source;
    }

    return source.filter((x) => {
        for(const field of fields) {
            let value = x[field] as unknown as string;

            if(value && value.toUpperCase().indexOf(search) > -1) {
                return true;
            }
        }

        return false;
    });
}
