import { React } from '../../Imports';

const styles = require("./PageContent.scss") as {
    main: string;
};

interface IPageContentProps {}

export class PageContent extends React.PureComponent<React.PropsWithChildren<IPageContentProps>, {}> {
    render(): JSX.Element {
        return (
            <div className={styles.main}>
                {this.props.children}
            </div>
        );
    }
}