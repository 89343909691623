import { bind, React } from '../../Imports';

import { Grid, Typography } from 'MaterialUIComponents';

import { stringItemFilter } from '$Components/Shared/DataUtility';
import { VPCameraCard  } from '$Components/Shared/VPCameraCard';
import { VPDeviceSearch } from '$Components/Shared/VPDeviceSearch'
;
import { CameraDeviceResponse } from '$Generated/api';
import { IDevicePairServiceInjectedProps, DevicePairService } from "$State/DevicePairFreezerService";

interface ICameraSelectBaseProps {
    onSelectCamera: (camera?: CameraDeviceResponse) => void;
}

type ICameraSelectProps = ICameraSelectBaseProps
    & IDevicePairServiceInjectedProps;

interface ICameraSelectState {
    cameras: CameraDeviceResponse[];
    cameraCount: number;
    selectedCameraId?: number;
}

const styles = require('./CameraSelect.scss') as {
    search: string;
    container: string;
    camera: string;
    selected: string;
    noCameras: string;
};

class _CameraSelect extends React.Component<ICameraSelectProps, ICameraSelectState> {
    state: ICameraSelectState = {
        cameras: [],
        cameraCount: 0,
        selectedCameraId: undefined
    };

    componentDidMount(): void {
        this.setFilteredCameras();
    }

    @bind
    selectCamera(camera?: CameraDeviceResponse): void {
        this.setState((state) => {
            // toggle selection
            const selectedCameraId = state.selectedCameraId !== camera?.id ? camera?.id : undefined;
            this.props.onSelectCamera(selectedCameraId ? camera : undefined);

            return {
                selectedCameraId: selectedCameraId
            };
        });
    }

    render(): JSX.Element {
        const { cameras, cameraCount, selectedCameraId } = this.state;

        return (
            <React.Fragment>
                <div className={styles.search}>
                    <VPDeviceSearch
                        label="Search"
                        foundCount={cameras.length}
                        totalCount={cameraCount}
                        onChange={this.setFilteredCameras}
                        showScanButton={true}
                    />
                </div>

                <Grid container className={styles.container}>
                    { !cameras.length ? (
                        <Typography component="h2" variant="h5" className={styles.noCameras}>
                            Camera not found. Please contact your Fleet Manager.
                        </Typography>
                    ) : cameras.map((x) => {
                        const isSelected: boolean = !!selectedCameraId && (x.id === selectedCameraId);

                        return (
                            <Grid item xs={12} lg={6} key={x.id} className={styles.camera}>
                                <VPCameraCard
                                    className={isSelected ? styles.selected : ''}
                                    onClick={() => this.selectCamera(x)}
                                    camera={x}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </React.Fragment>
        );
    }

    @bind
    private setFilteredCameras(search?: string): void {
        const freezer = this.props.devicePair.getState();
        const allCameras = freezer.cameraResults.data || [];

        //Trim so search allows leading/trailing spaces
        search = search?.trim();

        const cameras = stringItemFilter(allCameras, ['serialNumber', 'iccid'], search) as CameraDeviceResponse[];

        // deselect currently selected camera if it was filtered out
        if(!cameras.find(x => x.id === this.state.selectedCameraId)) {
            this.selectCamera(undefined);
        }

        // auto-select camera if only one found _and_ matches the search exactly (i.e., through a barcode scan)
        if((cameras.length === 1) && (cameras[0].serialNumber === search) && (this.state.selectedCameraId !== cameras[0].id)) {
            this.selectCamera(cameras[0]);
        }

        this.setState({
            cameras: cameras,
            cameraCount: allCameras.length
        });
    }
}

export const CameraSelect = DevicePairService.inject(_CameraSelect);
