import { React } from '../../Imports';
import { cx } from '@videoplatform/css-helpers';

import { Grid, GridProps, Typography } from 'MaterialUIComponents';

interface IVPCardFieldProps extends GridProps {
    label?: string;
    value?: string;
}

const styles = require('./VPCardGridField.scss') as {
    container: string;
    label: string;
    value: string;
};

class _VPCardGridField extends React.PureComponent<IVPCardFieldProps, {}> {
    render(): JSX.Element {
        const { label, value, className, ...other } = this.props;

        return (
            <Grid
                item
                className={cx([styles.container, className])}
                {...other}
            >
                { label && <Typography className={styles.label}>{ label }</Typography> }

                { value && <Typography className={styles.value}>{ value }</Typography> }
            </Grid>
        )
    }
}

export const VPCardGridField = _VPCardGridField;
