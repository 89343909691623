import { React, bind, withRouter, RouteComponentProps } from '../../Imports';
import { Button } from 'MaterialUIComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';

import { IdentityService, IIdentityServiceInjectedProps } from '$State/IdentityFreezerService';
import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { GetImageUrl } from '$Utilities/dataModelUtilities';

const styles = require('./PageHeader.scss') as {
    main: string;
    header: string;
    logo: string;
    fullImage: string;
    mobileImage: string;
    title: string;
    fleetName: string;
    pageTitle: string;
    logout: string;
    logoutButton: string;
    logoutText: string;
    actions: string;
    left: string;
    right: string;
};

type IPageHeaderProps = {
    pageTitle: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
} & RouteComponentProps &
    IConfigServiceInjectedProps &
    IIdentityServiceInjectedProps;
class _PageHeader extends React.PureComponent<IPageHeaderProps, NonNullable<unknown>> {
    @bind
    async onLogout(): Promise<void> {
        await this.props.identity.logout();

        this.props.history.push('/logout');
    }

    render(): JSX.Element {
        const { fleetName } = this.props.identity.getState();
        const currentImagesBucket = this.props.config.getState().clientConfigResults.data?.imagesBucket;
        const currentRegion = this.props.config.getState().clientConfigResults.data?.imagesBucketRegion;

        return (
            <div className={styles.main}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <img
                            className={styles.fullImage}
                            src={GetImageUrl(
                                currentImagesBucket ? currentImagesBucket : '',
                                currentRegion ? currentRegion : '',
                                'logo.png',
                            )}
                            alt="VideoProtects logo"
                        />
                        <img
                            className={styles.mobileImage}
                            src={GetImageUrl(
                                currentImagesBucket ? currentImagesBucket : '',
                                currentRegion ? currentRegion : '',
                                'logo-mobile.png',
                            )}
                            alt="VideoProtects logo"
                        />
                    </div>

                    <div className={styles.title}>
                        <div className={styles.fleetName}>{fleetName}</div>
                        <div className={styles.pageTitle}>{this.props.pageTitle}</div>
                    </div>

                    <div className={styles.logout}>
                        <Button className={styles.logoutButton} onClick={this.onLogout} variant="text" title="Log out">
                            <FontAwesomeIcon icon={faSignOut} />
                            <span className={styles.logoutText}>Log Out</span>
                        </Button>
                    </div>
                </div>

                {this.props.children && <div>{this.props.children}</div>}

                {this.props.left || this.props.right ? (
                    <div className={styles.actions}>
                        {this.props.left ? <div className={styles.left}>{this.props.left}</div> : null}

                        {this.props.right ? <div className={styles.right}>{this.props.right}</div> : null}
                    </div>
                ) : null}
            </div>
        );
    }
}

export const PageHeader = withRouter(IdentityService.inject(ConfigService.inject(_PageHeader)));
